import React, {useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Provider} from 'react-redux';
import store from './store';
import Header from "./components/header/Header";
import Footer from "./components/Footer";
import "semantic-ui-css/semantic.min.css";
import {loadUser} from "./store/actions/authAction";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import './css/App.css';

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Nunito',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(','),
  }
});

const App =() => {
  useEffect(()=> {
    store.dispatch(loadUser());
  }) 
  return (
    <MuiThemeProvider theme={theme}>
    <Provider store={store}>
   <div className="body">
      <Header />
      <Footer />
    </div>
    </Provider>
    </MuiThemeProvider>
  );
}

export default App;
