import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form, Button, Spinner } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { clearErrors } from "../../store/actions/errorAction";
import { getMasterQuestion } from "../../store/actions/masterAssessmentAction";
import {
  postMasterScore,
  getMasterScore,
} from "../../store/actions/masterScoreAction";
import "../../css/App.css";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import "./Assessment.css";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = (theme) => ({
  roots: {
    flexGrow: 1,
    margin: theme.spacing(0, 3),
  },
  contentHeader: {
    paddingTop: theme.spacing(20),
    paddingBottom: theme.spacing(5),
  },
  papers: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    paddingBottom: theme.spacing(5),
    marginTop: theme.spacing(4),
  },

  about: {
    paddingTop: theme.spacing(7),
  },
  mainHeading: {
    fontSize: "1.7em",
    color: "#474747",
  },
  heading: {
    fontSize: "1.5em",
    paddingLeft: theme.spacing(3),
    color: "#fff",
  },
  span: {
    paddingTop: theme.spacing(5),
  },
  courses: {
    fontSize: "1.2em",
    color: "#474747",
  },
  header3: {
    position: "relative",
    marginTop: "-45px",
    backgroundColor: "#ff9800",
    width: "100%",
  },
  headerText: {
    padding: theme.spacing(2.5, 0),
    color: "#474747",
  },

  headingSpace: {
    padding: theme.spacing(2, 0),
  },
  title: {
    marginBottom: theme.spacing(8),
  },
  container: {
    maxHeight: 440,
    maxWidth: "50em",
  },
  fab: {
    color: "#fff",
    fontSize: "2em",
    position: "relative",
    left: "10%",
    top: "30%",
  },
  header5: {
    position: "relative",
    marginTop: "-50px",
    backgroundColor: "#ff9800",
    width: "70px",
    height: "70px",
  },
  table: {
    minWidth: 650,
  },
});

const shuffle = (a) => {
  var j, x, i;
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
  return a;
};
let dt1, dt2;

const date_diff_indays = (date1, date2) => {
  dt1 = new Date(date1);
  dt2 = new Date(date2);
  return Math.floor(
    (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
      Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
      (1000 * 60 * 60 * 24)
  );
};

class MasterQuestions extends Component {
  state = {
    currentques: 0,
    id: "",
    answer: "",
    number: 0,
    userAnswer: null,
    submittedAns: [],
    question: "",
    options: {
      A: "",
      B: "",
      C: "",
      D: "",
    },
    min: 60,
    sec: 0,
    score: 0,
    selectedans: [],
    questions: this.props.masterAssessment.questionnaire.data
      ? shuffle(this.props.masterAssessment.questionnaire.data)
      : this.props.masterAssessment.questionnaire,
  };

  loadQuiz = () => {
    this.props.getMasterQuestion();
  };

  start = () => {
    const { score, selectedans } = this.state;
    this.myInterval = setInterval(() => {
      const { sec, min } = this.state;

      if (sec === 0) {
        this.setState((prevState) => ({
          sec: 59,
          min: prevState.min - 1,
        }));
      }
      if (sec > 0) {
        this.setState((prevState) => ({
          sec: prevState.sec - 1,
        }));
      }
      if (min === 0 && sec === 0) {
        const submission = {
          UserId: this.props.auth.user.data.id,
          score,
          selectedans,
        };
        this.props.postMasterScore(submission, this.props.auth.token);
        this.props.history.push("/dashboard");
      }
    }, 1000);
  };

  componentWillMount() {
    this.loadQuiz();
  }

  componentDidMount() {
    const { currentques, questions } = this.state;
    this.start();
    if (!this.props.masterAssessment.questionnaire.score) {
      if (
        !this.props.masterAssessment.loading &&
        this.props.masterAssessment.questionnaire.data
      ) {
        this.setState({
          questions: shuffle(this.props.masterAssessment.questionnaire.data),
        });
        if (questions !== null) {
          this.setState((state) => {
            return {
              loading: false,
              number: state.number + 1,
              id: questions[currentques].id,
              question: questions[currentques].question,
              options: {
                A: questions[currentques].optionA,
                B: questions[currentques].optionB,
                C: questions[currentques].optionC,
                D: questions[currentques].optionD,
              },
            };
          });
        }
      } else {
        this.loadQuiz();
      }
    }
  }

  componentWillUpdate(nextProps) {
    const { questions } = this.state;
    if (!this.props.masterAssessment.questionnaire.score) {
      if (
        nextProps.masterAssessment.questionnaire.data &&
        questions.length === 0
      ) {
        this.setState({
          questions: shuffle(nextProps.masterAssessment.questionnaire.data),
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentques, number, score, selectedans, questions } = this.state;
    if (questions !== prevState.questions) {
      this.setState((state) => {
        return {
          number: state.number + 1,
          id: questions[currentques].id,
          question: questions[currentques].question,
          options: {
            A: questions[currentques].optionA,
            B: questions[currentques].optionB,
            C: questions[currentques].optionC,
            D: questions[currentques].optionD,
          },
        };
      });
    }

    if (number > 40) {
      const submission = {
        UserId: this.props.auth.user.data.id,
        score,
        selectedans,
      };
      this.props.postMasterScore(submission, this.props.auth.token);
      this.props.history.push("/dashboard");
    }
    if (currentques !== prevState.currentques) {
      this.setState(() => {
        return {
          number: number + 1,
          id: questions[currentques].id,
          question: questions[currentques].question,
          options: {
            A: questions[currentques].optionA,
            B: questions[currentques].optionB,
            C: questions[currentques].optionC,
            D: questions[currentques].optionD,
          },
        };
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.myInterval);
  }

  checkAnswer = (answer) => {
    const { options } = this.state;
    var key = Object.keys(options).filter(function (key) {
      return options[key] === answer;
    })[0];

    this.setState({
      answer,
      userAnswer: key,
    });
  };

  submitQuiz = () => {
    const {
      currentques,
      userAnswer,
      submittedAns,
      selectedans,
      id,
      questions,
    } = this.state;
    selectedans.push({ userAnswer, id });
    this.setState((state) => ({
      currentques: state.currentques + 1,
      userAnswer: null,
      question: questions[currentques].question,
      answer: null,
      selectedans: [...selectedans],
    }));

    if (userAnswer === questions[currentques].answer) {
      submittedAns.push(userAnswer);
      const result = submittedAns.length;
      this.setState({
        submittedAns: [...submittedAns],
        score: result * 2.5,
      });
    }
  };

  render() {
    const { classes } = this.props;
    const {
      question,
      id,
      userAnswer,
      options,
      number,
      min,
      sec,
      answer,
      questions,
    } = this.state;
    return (
      <div className={classes.roots}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <div className={classes.title}></div>
            <Paper className={classes.papers}>
              <Paper className={classes.header3}>
                <div className={classes.headerText}>
                  <Typography paragraph className={classes.heading}>
                    Practitioner Assessment
                  </Typography>
                </div>
              </Paper>

              {this.props.masterAssessment.questionnaire.score &&
              30 -
                date_diff_indays(
                  this.props.masterAssessment.questionnaire.score[0].createdAt,
                  new Date()
                ) >
                0 ? (
                <div className="test-countdown">
                  <h2>
                    {30 -
                      date_diff_indays(
                        this.props.masterAssessment.questionnaire.score[0]
                          .createdAt,
                        new Date()
                      )}{" "}
                    days left
                  </h2>
                </div>
              ) : (
                ""
              )}
              <div>
                {!this.props.masterAssessment.questionnaire.score ? (
                  !this.props.masterAssessment.loading ? (
                    <div>
                      <Grid container spacing={3}>
                        <Grid
                          container
                          direction="row"
                          justify="flex-end"
                          alignItems="flex-start">
                          <Paper className={classes.header5}>
                            <Typography className={classes.fab}>
                              <span>
                                {min}:{sec}
                              </span>{" "}
                            </Typography>
                          </Paper>
                        </Grid>

                        <Grid item xs={12} md={12} sm={12}>
                          <Form>
                            <div key={id}>
                              <Typography id="unselectable">
                                {number}. {question}
                              </Typography>

                              <p
                                size="md"
                                id="unselectable"
                                color="info"
                                className={`options ${
                                  answer === options.A ? "selected" : null
                                }`}
                                onClick={() => this.checkAnswer(options.A)}>
                                {options.A}
                              </p>
                              <p
                                size="md"
                                id="unselectable"
                                color="info"
                                className={`options ${
                                  answer === options.B ? "selected" : null
                                }`}
                                onClick={() => this.checkAnswer(options.B)}>
                                {" "}
                                {options.B}
                              </p>
                              <p
                                size="md"
                                id="unselectable"
                                color="info"
                                className={`options ${
                                  answer === options.C ? "selected" : null
                                }`}
                                onClick={() => this.checkAnswer(options.C)}>
                                {options.C}
                              </p>
                              <p
                                size="md"
                                id="unselectable"
                                color="info"
                                className={`options ${
                                  answer === options.D ? "selected" : null
                                }`}
                                onClick={() => this.checkAnswer(options.D)}>
                                {options.D}
                              </p>
                              <hr />
                              {number === 40 ? (
                                <Button
                                  type="submit"
                                  color="success"
                                  size="sm"
                                  block
                                  onClick={this.submitQuiz}
                                  disabled={!userAnswer}
                                  style={{ marginTop: "10px" }}>
                                  Finish
                                </Button>
                              ) : (
                                <Button
                                  type="submit"
                                  color="success"
                                  size="sm"
                                  block
                                  onClick={this.submitQuiz}
                                  disabled={!userAnswer}
                                  style={{
                                    marginTop: "10px",
                                    backgroundColor: "#ff9800",
                                    border: "#ff9800",
                                  }}>
                                  Submit
                                </Button>
                              )}
                            </div>
                          </Form>
                        </Grid>
                      </Grid>
                    </div>
                  ) : (
                    <div id="spinner">
                      <Spinner type="grow" color="primary" />
                      <Spinner type="grow" color="secondary" />
                      <Spinner type="grow" color="success" />
                      <Spinner type="grow" color="danger" />
                      <Spinner type="grow" color="warning" />
                      <Spinner type="grow" color="info" />
                      <Spinner type="grow" color="light" />
                      <Spinner type="grow" color="dark" />
                    </div>
                  )
                ) : (
                  ""
                )}
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

MasterQuestions.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
  clearErrors: PropTypes.func.isRequired,
  getMsterQuestion: PropTypes.func.isRequired,
  masterAssessment: PropTypes.object.isRequired,
  masterScore: PropTypes.object.isRequired,
  postMasterScore: PropTypes.func.isRequired,
  getMasterScore: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  masterAssessment: state.masterAssessment,
  masterScore: state.masterScore,
  error: state.error,
});

export default connect(mapStateToProps, {
  getMasterQuestion,
  clearErrors,
  postMasterScore,
  getMasterScore,
})(withStyles(useStyles)(withRouter(MasterQuestions)));
