import React from 'react';
import Drawer from "./Drawer";


const Header = () => {
    return (
        <div >
            <Drawer />   
        </div>
    );
}

export default Header;