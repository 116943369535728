import {FINAL_FOUNDATION_SCORE, SCORE_LOADING, GET_FOUNDATION_SCORE, CLEAR_SCORE, GET_ALL_FOUNDATION_SCORES, POST_FOUNDATION_ANS} from "./types";
import axios from '../../utils/axios-base';
import { returnErrorrs } from './errorAction';


export const setScoreLoading = () => {
    return {
        type: SCORE_LOADING
    }
};

export const postScore = ( score, token) => dispatch => {
    axios.post("/api/user/foundationscore", score,  {
        headers: {
            "Authorization": `${token}`
        }
        })
        .then(res => {
            dispatch({
                type: FINAL_FOUNDATION_SCORE,
                payload: res.data
            });
            dispatch(getFoundationScore());
            
        })
        .catch(err => dispatch(returnErrorrs(err.response.data, err.response.status)));
};

export const postAns = (answer) => dispatch => {
            dispatch({
                type: POST_FOUNDATION_ANS,
                payload: answer
            });
};

export const getFoundationScore = () => (dispatch, getState) => {
    dispatch(setScoreLoading());
    axios.get("/api/user/foundationscore", tokenConfig(getState))
        .then(res => {
            dispatch({
                type: GET_FOUNDATION_SCORE,
                payload: res.data
            });
            
        })
        .catch(err => dispatch(returnErrorrs(err.response.data, err.response.status)));
};

export const getAllScore = () => (dispatch, getState) => {
    // dispatch(setScoreLoading());
    axios.get("/api/admin/foundationscores", tokenConfig(getState))
        .then(res => {
            dispatch({
                type: GET_ALL_FOUNDATION_SCORES,
                payload: res.data
            });
            
        })
        .catch(err => dispatch(returnErrorrs(err.response.data, err.response.status)));
};

export const clearFoundationScore= () => {
    return {
        type: CLEAR_SCORE
    }
};

// Setup config/headers and token
export const tokenConfig = getState => {
    // Get token from local storege
    const token = getState().auth.token;

    // Headers
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    if (token) {
        config.headers['Authorization'] = `${token}`;

    }
    return config;
};