import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Label,
  Input,
} from "reactstrap";
import "./updateModal.css";

const UpdateModal = ({ showModal, onCancel, user, handleUpdate }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [foundation, setFoundation] = useState(user.fA);
  const [master, setMaster] = useState(user.mA);

  useEffect(() => {
    setModalOpen(showModal);
  }, [showModal]);

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  const handleUpdateUser = () => {
    handleUpdate({ fA: foundation, mA: master });
    // Implement your logic to update the user data here
    // For this example, let's just close the modal
    handleCloseModal();
  };

  const handleCloseModal = () => {
    //setModalOpen(false);
    onCancel(); // Notify the parent component about the modal cancel event
  };
  return (
    <div>
      <Modal isOpen={showModal} toggle={toggleModal} size="md">
        <ModalBody>
          <h3 className="mt-4 mb-5">
            Update {user.lastname + " " + user.firstname} Access
          </h3>
          <div className="radio-group">
            <Label>
              Foundation Access
              <br />
              <div>
                <Label>
                  <Input
                    type="radio"
                    value={true}
                    checked={foundation === true}
                    onChange={(e) => {
                      setFoundation(!foundation);
                    }}
                  />{" "}
                  True
                </Label>{" "}
              </div>
              <div>
                <Label>
                  <Input
                    type="radio"
                    value={false}
                    checked={foundation === false}
                    onChange={(e) => {
                      setFoundation(!foundation);
                    }}
                  />{" "}
                  False
                </Label>
              </div>
            </Label>
          </div>
          <div className="radio-group">
            <Label>
              Master Access
              <br />
              <div>
                <Label>
                  <Input
                    type="radio"
                    value={true}
                    checked={master === true}
                    onChange={(e) => {
                      setMaster(!master);
                    }}
                  />{" "}
                  True
                </Label>{" "}
              </div>
              <div>
                <Label>
                  <Input
                    type="radio"
                    value={false}
                    checked={master === false}
                    onChange={(e) => {
                      setMaster(!master);
                    }}
                  />{" "}
                  False
                </Label>
              </div>
            </Label>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleUpdateUser}>
            Upload
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default UpdateModal;
