import React, {useState, useEffect} from "react";
import {  Form } from 'semantic-ui-react';
import {postMasterQuestion} from "../../store/actions/masterAssessmentAction";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { clearErrors } from "../../store/actions/errorAction";
import { Alert } from "reactstrap";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({
    
  roots: {
      flexGrow: 1,
      margin: theme.spacing(0, 3)
  },
  contentHeader: {
      paddingTop: theme.spacing(20),
      paddingBottom: theme.spacing(5),

  },
  papers: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      paddingBottom: theme.spacing(5),
      marginTop: theme.spacing(4)
  },
  text: {
      color: "red"
  },
  about: {
      paddingTop: theme.spacing(7),
  },
  abouttext: {
      fontSize: "1.2em",
      color: "#474747"
  },
  mainHeading: {
      fontSize: "1.7em",
      color: "#474747"
  },
  heading: {
      fontSize: "1.5em",
      paddingLeft: theme.spacing(3),
      color: "#fff"
  },
  span: {
      paddingTop: theme.spacing(5),
  },
  courses: {
      fontSize: "1.2em",
      color: "#474747"
  },
  header3: {
      position: 'relative',
      marginTop: "-45px",
      backgroundColor: "#9c27b0",
      width: "100%"
  },
  headerText: {
      padding: theme.spacing(2.5, 0),
      color: "#474747"
  },

  headingSpace: {
      padding: theme.spacing(2, 0)
  },
  title: {
      marginBottom: theme.spacing(8)
  },

}));

const PostMasterQuestions = (props) => {
  const classes = useStyles();
    const [questions, setQuestions] = useState({
        question: "",
        optionA : "", 
        optionB : "",
        optionC : "",
        optionD: "",
        answer: ""
    });

    const [errs, setErrs] = useState({
      question: "",
      optionA : "", 
      optionB : "",
      optionC : "",
      optionD: "",
      answer: ""
  });

  const [msg, setMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    setMsg('');
  }, [msg]);

  useEffect(() => {
    if (!initialized) {
      if (props.error.msg) {
        setMsg(props.error.msg.error);
        setInitialized(true);
      }
      
        if (props.masterAssessment.message) {
          setSuccessMsg(props.masterAssessment.message);
          setInitialized(true);
        }
      
    } 
    if (props.masterAssessment.message) {
      setSuccessMsg(props.masterAssessment.message);
      setInitialized(true);
    }
  }, [props, initialized]);

    const change = (e, validators) => {
      let { name, value } = e.target;
      let targets = { ...questions, [name]: value };
      setQuestions(targets);
      setMsg("");
      setSuccessMsg('')
  
      handleValidation(e.target, validators)
    };

    const handleValidation = (target, validators) => {
      validators.forEach(validation => {
        const result = validation(target.value)
        const errors = errs[target.name]
  
        if (result.valid) {
          if (errors.includes(result.message)) {
            setErrs(prevState => ({
              ...prevState,
              [target.name]: errors.filter(error => error !== result.message)
            }))
          }
        } else {
          if (!errors.includes(result.message)) {
            setErrs(prevState => ({
              ...prevState,
              [target.name]: [...errors, result.message]
            }))
          }
        }
      })
    };
  
    const noBlanks = (value) => {
      return {
        valid: value.replace(/\s+/g, "").length > 0,
        message: "Field cannot be blank"
      }
    };

    const submit = (e) => {
      e.preventDefault();
      props.postMasterQuestion(questions, props.auth.token);
      props.clearErrors();
      setQuestions({
        question: "",
        optionA : "", 
        optionB : "",
        optionC : "",
        optionD: "",
        answer: "" 
      })
    }
    return (
      <div className={classes.roots}>
      <div >
          <Grid container spacing={3} >
              <Grid item xs={12} sm={12} md={12}>
                  <div className={classes.title}>
                      {/* <Typography paragraph className={classes.mainHeading}>Practitioner Questions</Typography>
                      <Divider /> */}
                  </div>
                  <Paper className={classes.papers}>
                      <Paper className={classes.header3}>
                          <div className={classes.headerText}>
                              <Typography paragraph className={classes.heading}>
                              New Practitioner Questions
                              </Typography>
                          </div>
                      </Paper>
                      <div className={classes.about}>
        <Form onSubmit={submit} style={{ 'margin': '0', 'padding': '10px 15px' }} method="POST">
           {msg ? <Alert color="danger">{msg}</Alert> : ""}
            {successMsg ? <Alert >{successMsg.toUpperCase()}</Alert> : ""}
        <Form.Field>
          <label className="important">
            <input type="text" name="question" onChange={e => change(e, [noBlanks])} placeholder="Question" value={questions.question} />
          </label>
        </Form.Field>
        <Form.Field>
          <label className="important"> 
            <Form.Input type="text" name="optionA" onChange={e => change(e, [noBlanks])} placeholder="optionA" value={questions.optionA} />
          </label>
        </Form.Field>

        <Form.Field>
          <label className="important">
            <input type="text" name="optionB" onChange={e => change(e, [noBlanks])} placeholder="optionB" value={questions.optionB} />
          </label>
        </Form.Field>
        <Form.Field>
          <label className="important">
            <input type="text" name="optionC" onChange={e => change(e, [noBlanks])} placeholder="optionC" value={questions.optionC} />
          </label>
        </Form.Field>
        <Form.Field>
          <label className="important">
            <input type="text" name="optionD" onChange={e => change(e, [noBlanks])} placeholder="optionD" value={questions.optionD} />
          </label>
        </Form.Field>
        <Form.Field>
          <label className="important">
            <input type="text" name="answer" onChange={e => change(e, [noBlanks])} placeholder="answer" value={questions.answer} />
          </label>
        </Form.Field>

        <Button variant="contained" style={{ marginTop: '20px', backgroundColor: "#9c27b0", color: "#fff" }} type="submit">Submit</Button>

      </Form>
                            </div>
                            </Paper>
                </Grid>
                </Grid>
                     
            </div>
        </div>
    )
};
PostMasterQuestions.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
  clearErrors: PropTypes.func.isRequired,
  postMasterQuestion: PropTypes.func.isRequired,
  masterAssessment: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  masterAssessment: state.masterAssessment,
  error: state.error,
});

export default connect(
  mapStateToProps,
  { postMasterQuestion, clearErrors }
)(withRouter(PostMasterQuestions));
