import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Alert } from "reactstrap"
import PropTypes from 'prop-types';
import '../../css/App.css';
import { Form,  Label, Input } from 'semantic-ui-react';
import { register } from '../../store/actions/authAction';
import { clearErrors } from "../../store/actions/errorAction";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({

  roots: {
    flexGrow: 1,
    margin: theme.spacing(0, 3)
  },
  contentHeader: {
    paddingTop: theme.spacing(20),
    paddingBottom: theme.spacing(5),

  },
  papers: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    paddingBottom: theme.spacing(5),
    marginTop: theme.spacing(4)
  },
  text: {
    color: "red"
  },
  about: {
    paddingTop: theme.spacing(7),
  },
  abouttext: {
    fontSize: "1.2em",
    color: "#474747"
  },
  mainHeading: {
    fontSize: "1.7em",
    color: "#474747"
  },
  heading: {
    fontSize: "1.5em",
    paddingLeft: theme.spacing(3),
    color: "#fff"
  },
  span: {
    paddingTop: theme.spacing(5),
  },
  courses: {
    fontSize: "1.2em",
    color: "#474747"
  },
  header3: {
    position: 'relative',
    marginTop: "-45px",
    backgroundColor: "#f44336",
    width: "100%"
  },
  headerText: {
    padding: theme.spacing(2.5, 0),
    color: "#474747"
  },

  headingSpace: {
    padding: theme.spacing(2, 0)
  },
  title: {
    marginBottom: theme.spacing(8)
  },

}));

const Register = (props) => {
  const classes = useStyles();
  const [user, setUser] = useState({
    firstname: "",
    lastname: "",
    organisation: "",
    email: ""
  });

  const [fA, setFA] = useState("");
  const [mA, setMA] = useState("");

  const { firstname, lastname, email, organisation } = user;
  const [initialized, setInitialized] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [msg, setMsg] = useState('');

  // Set errs to an empty object
  const [errs, setErrs] = useState({
    firstname: [],
    lastname: [],
    organisation: [],
    email: []
  });


  useEffect(() => {
    setMsg('');
    setSuccessMsg('');
  }, [msg, successMsg])


  useEffect(() => {
    if (!initialized) {
      if (props.error.msg.message) {
        setMsg(props.error.msg.message);
        setInitialized(true);
      }
      if (props.auth.newUser) {
        if (props.auth.newUser.message) {
          setSuccessMsg(props.auth.newUser.message);
          setInitialized(true);
        }
      }
    } else {
      if (props.error.msg.message) {
        setMsg(props.error.msg.message)
      }
      if (props.auth.newUser) {
        if (props.auth.newUser.message) {
          setSuccessMsg(props.auth.newUser.message);
          setInitialized(true);
        }
      }
    }
  }, [props, initialized]);

  const change = (e, validators) => {
    let { name, value } = e.target;
    let targets = { ...user, [name]: value };
    setUser(targets);
    setMsg("");
    setSuccessMsg('')

    handleValidation(e.target, validators)
  };

  const changeFACourses = (e, { value }) => {
    setFA(value);
  };

  const changeMACourses = (e, { value }) => {
    setMA(value);
  }

  const handleValidation = (target, validators) => {
    validators.forEach(validation => {
      const result = validation(target.value)
      const errors = errs[target.name]

      if (result.valid) {
        if (errors.includes(result.message)) {
          setErrs(prevState => ({
            ...prevState,
            [target.name]: errors.filter(error => error !== result.message)
          }))
        }
      } else {
        if (!errors.includes(result.message)) {
          setErrs(prevState => ({
            ...prevState,
            [target.name]: [...errors, result.message]
          }))
        }
      }
    })
  };

  const noBlanks = (value) => {
    return {
      valid: value.replace(/\s+/g, "").length > 0,
      message: "Field cannot be blank"
    }
  };

  const submitForm = e => {
    e.preventDefault();
    const details = { firstname, lastname, email, organisation, fA, mA };
    props.register(details, props.auth.token);
    props.clearErrors();
    setUser({
      firstname: "",
      lastname: "",
      organisation: "",
      email: ""
    });
    setFA("");
    setMA("");
  }

  return (
    <div className={classes.roots}>
      <div>
        <Grid container spacing={3} >
          <Grid item xs={12} sm={12} md={12}>
            <div className={classes.title}>
              {/* <Typography paragraph className={classes.mainHeading}>Registration</Typography>
              <Divider /> */}
            </div>
            <Paper className={classes.papers}>
              <Paper className={classes.header3}>
                <div className={classes.headerText}>
                  <Typography paragraph className={classes.heading}>
                    Registration
                                    </Typography>
                </div>
              </Paper>
              <div className={classes.about}>
              <Form onSubmit={submitForm} method="POST">
                  {msg ? <Alert color="danger">{msg}</Alert> : ""}
                  {successMsg ? <Alert >{successMsg.toUpperCase()}</Alert> : ""}
                  <label htmlFor="firstname">First Name</label>
                  <Form.Field>
                    <Input type='text' placeholder='First Name'
                      onChange={e => change(e, [noBlanks])}
                      value={firstname}
                      name="firstname"
                      icon="user"
                      iconPosition='left' />
                    {errs.firstname.length > 0 ?
                      <Label basic color='red' ribbon>
                        {errs.firstname}
                      </Label>
                      : ""}
                  </Form.Field>
                  <label htmlFor="lastname">Last Name</label>
                  <Form.Field>
                    <Input type='text'
                      placeholder='Last Name'
                      onChange={e => change(e, [noBlanks])}
                      value={lastname}
                      name="lastname"
                      icon="user"
                      iconPosition='left' />
                    {errs.lastname.length > 0 ?
                      <Label basic color='red' ribbon>
                        {errs.lastname}
                      </Label>
                      : ""}
                  </Form.Field>
                  <label htmlFor="email">Email</label>
                  <Form.Field>
                    <Input type='text'
                      placeholder='Email Address'
                      onChange={e => change(e, [noBlanks])}
                      value={email}
                      name="email"
                      icon="mail"
                      iconPosition='left' />
                    {errs.email.length > 0 ?
                      <Label basic color='red' ribbon>
                        {errs.email}
                      </Label>
                      : ""}
                  </Form.Field>
                  <label htmlFor="email">Organisation</label>
                  <Form.Field>
                    <Input type='text'
                      placeholder='Organisation'
                      onChange={e => change(e, [noBlanks])}
                      value={organisation}
                      name="organisation"
                      icon="industry"
                      iconPosition='left' />
                    {errs.organisation.length > 0 ?
                      <Label basic color='red' ribbon>
                        {errs.organisation}
                      </Label>
                      : ""}
                  </Form.Field>
                  <label>FCA</label>
                  <Form.Group inline>
                    <Form.Radio
                      label='False'
                      value="false"
                      checked={fA === 'false'}
                      onChange={changeFACourses}
                    />

                    <Form.Radio
                      label='True'
                      value="true"
                      checked={fA === 'true'}
                      onChange={changeFACourses}
                    />
                  </Form.Group>
                  <label>MCA</label>
                  <Form.Group inline>

                    <Form.Radio
                      label='False'
                      value="false"
                      checked={mA === 'false'}
                      onChange={changeMACourses}
                    />
                    <Form.Radio
                      label='True'
                      value="true"
                      checked={mA === 'true'}
                      onChange={changeMACourses}
                    />
                  </Form.Group>


                  <div className="reg_submit">
                    <div className="btns">
                      <Button variant="contained" style={{ marginTop: '20px', backgroundColor: "#f44336", color: "#fff" }} type="submit"> Create </Button>
                    </div>
                  </div>
              </Form>
              </div>
            </Paper>
          </Grid>
        </Grid>

      </div>
    </div>
  )
}

register.propTypes = {
  error: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  error: state.error,
  auth: state.auth
});


export default connect(
  mapStateToProps,
  { register, clearErrors }
)(withRouter(Register));