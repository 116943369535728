import {
    USER_LOADING,
    USER_LOADED,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    AUTH_ERROR,
    REG_FAIL,
    REG_SUCCESS,
    PROFILE_UPDATE,
    UPDATE_FAILED,
    USERS_LOADED,
    USERS_LOADING,
    PASSWORD_FORGOT_SUCCESS,
    PASSWORD_FORGOT_FAILED,
    PASSWORD_RESET_LINK_FAIL,
    PASSWORD_RESET_LINK_SUCCESS,
    UPDATE_USERS_ACCESS,
    CLEAR_DATA,

} from '../actions/types';
import isEmpty from "../../validation/is-empty";

const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: false,
    isLoading: false,
    user: "",
    newUser: null,
    users: [], 
    email: ""
}

export default function (state = initialState, action) {
    switch (action.type) {
        case USERS_LOADING:
        case USER_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                isLoading: false,
                user: action.payload
            }
        case USERS_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                isLoading: false,
                users: action.payload
            }
        case REG_SUCCESS:
            return {
                ...state,
                newUser: action.payload,
                isLoading: false,
            };
        case LOGIN_SUCCESS:
            localStorage.setItem('token', action.payload.token);
            return {
                ...state,
                ...action.payload,
                isAuthenticated: !isEmpty(action.payload),
                isLoading: false,
            };
        case PROFILE_UPDATE:
            return {
                ...state,
                isAuthenticated: true,
                isLoading: false,
                user: action.payload,
                update: true,
            }
            case PASSWORD_FORGOT_SUCCESS:
            return {
                ...state,
                email: action.payload,
                isLoading: false

            }
            case PASSWORD_RESET_LINK_SUCCESS:
            return {
                ...state,
                user: action.payload,
                isLoading: false,
                update: true
            }
            case PASSWORD_FORGOT_FAILED:
            case PASSWORD_RESET_LINK_FAIL:
            return {
                ...state,
                ...action.payload,
                isLoading: false,
                update: false,

            }
        case UPDATE_FAILED:
            return {
                ...state,
                ...action.payload,
                update: false,
                isLoading: false,
            }
        case REG_FAIL:
            return {
                ...state,
                isLoading: false
            };
        case LOGIN_FAIL:
        case LOGOUT_SUCCESS:
        case AUTH_ERROR:
            localStorage.removeItem('token');
            localStorage.removeItem('state');
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                isLoading: false,
                user: "",
                users: []
            };
        case UPDATE_USERS_ACCESS:
            return {
                ...state,
                // access: state.users.filter(user => console.log(user))
            };
            case CLEAR_DATA:
                return {
                    ...state,
                    email : ""
                }
        default:
            return state;
    }
}
