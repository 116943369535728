import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import PropTypes from 'prop-types';

const PrivateRoute = ({ component: Component, auth, path, ...rest }) => (
    <Route
    path={path}
        {...rest}
        render={props =>
            auth.isAuthenticated ? (
                <Component {...props} />

            ) : (
                <Redirect to={{pathname: '/login', 
                state: 
                {from: props.location}
            }}/>
                )
        }
    />
);

PrivateRoute.propTypes = {
    auth: PropTypes.object.isRequired,
    error: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    error: state.error
})

export default connect(
    mapStateToProps
)(PrivateRoute);
