import {combineReducers} from 'redux';
import auth from './authReducer';
import error from './errorReducer';
import foundationAssessment from "./foundationAssessmentReducer";
import masterAssessment from "./masterAssessmentReducer";
import foundationScore from "./foundationScoreReducer";
import masterScore from "./masterScoreReducer";
 
export default combineReducers({
    auth,
    error,
    foundationAssessment,
    masterAssessment,
    foundationScore,
    masterScore
});