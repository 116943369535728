import React, { Fragment } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PowerIcon from '@material-ui/icons/PowerSettingsNew';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { logout } from '../../store/actions/authAction';
import{clearFoundationQuestions} from "../../store/actions/foundationAssessmentAction";
import{clearMasterQuestions} from "../../store/actions/masterAssessmentAction";
import {  clearFoundationScore} from "../../store/actions/foundationScoreAction";
import {  clearMasterScore} from "../../store/actions/masterScoreAction";
import { clearErrors } from "../../store/actions/errorAction";
import { NavLink} from 'reactstrap';

const Logout = (props) => {
    const onLogout = (e) => {
        props.logout();
        props.clearFoundationQuestions();
        props.clearMasterQuestions();
        props.clearFoundationScore();
        props.clearMasterScore();
    }

    return (
        <Fragment>
            <NavLink href="#" onClick={onLogout} style={{color: "#fff"}}>  
          <ListItem button>
          <ListItemIcon><PowerIcon style={{color: "#fff"}}/></ListItemIcon>
            <ListItemText primary={"Logout"} />
          </ListItem>
          </NavLink>
        </Fragment>
    )
}

Logout.propTypes = {
    logout: PropTypes.func.isRequired,
    clearFoundationQuestions: PropTypes.func.isRequired,
    clearMasterQuestions: PropTypes.func.isRequired,
    foundationAssessment: PropTypes.object.isRequired,
    masterAssessment: PropTypes.object.isRequired,
    clearFoundationScore: PropTypes.func.isRequired,
    clearMasterScore: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
    error: state.error,
    foundationAssessment: state.foundationAssessment,
    masterAssessment: state.masterAssessment,
    foundationScore: state.foundationScore,
    masterScore: state.masterScore
  });
export default connect(
    mapStateToProps,
    { logout, clearErrors, clearFoundationQuestions, clearFoundationScore, clearMasterQuestions, clearMasterScore}
)(Logout);