import React from 'react';
import { Route, Switch, Redirect} from 'react-router-dom';
import Signup from "../auth/Register";
import Login from "../auth/Login";
import PrivateRoute from "./PrivateRoute";
import AdminRoute from "./AdminRoute";
import FoundationInstructions from '../instructions/FoundationInstructions';
import MasterInstructions from '../instructions/MasterInstructions';
import EditDashboard from '../profile/Profile';
import FoundationAssessment from "../assessments/Foundation";
import FailedFoundationAssessment from "../assessments/FailedQuestions";
import FailedMasterAssessment from "../assessments/MasterFailedQuestions";
import MasterAssessment from "../assessments/Master.jsx";
import Users from "../admin/Users";
import ForgotPassword from "../auth/ForgotPassword";
import ResetPassword from "../auth/ResetPassword";
import Scores from "../scores/Scores";
import NewFoundationQuestions from "../assessments/PostFoundationQuestions";
import NewMasterQuestions from "../assessments/PostMasterQuestions";
import Dashboard from "../home/Dashboard";

const Router = () => {

    return (
        <div >
            <Switch>
                <Route exact path="/forgotpassword" component={ForgotPassword} />
                <Route exact path="/resetpassword/:resetToken" component={ResetPassword} />
                <Route exact path="/login" component={Login} />
                <AdminRoute exact path="/admin/new/client" component={Signup} />
                <AdminRoute exact path="/admin/list/clients" component={Users} />
                <AdminRoute exact path="/admin/list/all-scores" component={Scores} />
                <PrivateRoute exact path="/foundation-assessment" component={FoundationAssessment} />
                <PrivateRoute exact path="/foundation-failed" component={FailedFoundationAssessment} />
                <PrivateRoute exact path="/master-failed" component={FailedMasterAssessment} />
                <PrivateRoute exact path="/master-assessment" component={MasterAssessment} />
                <PrivateRoute exact path="/foundation-instructions" component={FoundationInstructions} />
                <PrivateRoute exact path="/master-instructions" component={MasterInstructions} />
                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                <PrivateRoute exact path="/edit-profile" component={EditDashboard} />
                <PrivateRoute exact path="/admin/new-foundation-questions" component={NewFoundationQuestions} />
                <PrivateRoute exact path="/admin/new-master-questions" component={NewMasterQuestions} />
                <Route exact path="/" render={() => <Redirect to="/login" />} />
            </Switch>
        </div>
    );
}

export default Router;