import axios from "../../utils/axios-base";
import { returnErrorrs } from "./errorAction";
import {
  USER_LOADING,
  USER_LOADED,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  AUTH_ERROR,
  REG_FAIL,
  REG_SUCCESS,
  PROFILE_UPDATE,
  UPDATE_USERS_ACCESS,
  UPDATE_FAILED,
  USERS_LOADING,
  USERS_LOADED,
  UPDATE_USER_ACCESS_FAILED,
  PASSWORD_FORGOT_FAILED,
  PASSWORD_FORGOT_SUCCESS,
  PASSWORD_RESET_LINK_FAIL,
  PASSWORD_RESET_LINK_SUCCESS,
  CLEAR_DATA,
} from "./types";

// Check token and load user
export const loadUser = () => (dispatch, getState) => {
  // User loading
  dispatch({
    type: USER_LOADING,
  });

  axios
    .get("/api/user/", tokenConfig(getState))
    .then((res) => {
      console.log(res);
      dispatch({
        type: USER_LOADED,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrorrs(err.response.data, err.response.status));
      dispatch({
        type: AUTH_ERROR,
      });
    });
};

// GET ALL USERS (ADMIN)
export const loadUsers = (searchQuery) => (dispatch, getState) => {
  // User loading
  dispatch({
    type: USERS_LOADING,
  });
  const url = searchQuery
    ? `/api/admin/users?searchQuery=${encodeURIComponent(searchQuery)}`
    : "/api/admin/users/";
  axios
    .get(url, tokenConfig(getState))
    .then((res) =>
      dispatch({
        type: USERS_LOADED,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch(returnErrorrs(err.response.data, err.response.status))
    );
};

// Register User
export const register = (userData, token) => (dispatch) => {
  // Headers
  const config = {
    headers: {
      Authorization: `${token}`,
    },
  };

  axios
    .post("/api/user/signup/", userData, config)
    .then((res) => {
      dispatch({
        type: REG_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(
        returnErrorrs(err.response.data, err.response.status, "REG_FAIL")
      );
      dispatch({
        type: REG_FAIL,
      });
    });
};

// LOGIN USER
export const login = (userData) => (dispatch) => {
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  axios
    .post("/api/auth", userData, config)
    .then((res) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      dispatch(loadUser());
    })
    .catch((err) => {
      dispatch(returnErrorrs(err.response.data, err.response.status));
      dispatch({
        type: LOGIN_FAIL,
      });
    });
};

// UPDATE USER DETAILS
export const updateDetails = (userData, token) => (dispatch) => {
  axios
    .put("/api/user/update", userData, {
      headers: {
        Authorization: `${token}`,
      },
    })
    .then((res) => {
      dispatch({
        type: PROFILE_UPDATE,
        payload: res.data,
      });
      dispatch(loadUser());
    })
    .catch((err) => {
      dispatch(returnErrorrs(err.response.data, err.response.status));
      dispatch({
        type: UPDATE_FAILED,
      });
    });
};
// UPDATE USER ACCESS
export const updateAccess = (userData, token) => (dispatch) => {
  axios
    .put("/api/admin/user/update", userData, {
      headers: {
        Authorization: `${token}`,
      },
    })
    .then((res) => {
      dispatch({
        type: UPDATE_USERS_ACCESS,
        payload: res.data,
      });
      dispatch(loadUser());
    })
    .catch((err) => {
      dispatch(returnErrorrs(err.response.data, err.response.status));
      dispatch({
        type: UPDATE_USER_ACCESS_FAILED,
      });
    });
};

// FORGOT PASSWORD
export const forgotPassword = (email) => (dispatch) => {
  axios
    .post("/api/auth/forgotpassword", email)
    .then((res) => {
      dispatch({
        type: PASSWORD_FORGOT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrorrs(err.response.data, err.response.status));
      dispatch({
        type: PASSWORD_FORGOT_FAILED,
      });
    });
};

// RESET PASSWORD
export const resetPassword = (details, resetToken) => (dispatch) => {
  axios
    .put(`/api/auth/resetpassword/${resetToken}`, details)
    .then((res) => {
      dispatch({
        type: PASSWORD_RESET_LINK_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrorrs(err.response.data, err.response.status));
      dispatch({
        type: PASSWORD_RESET_LINK_FAIL,
      });
    });
};

// Logout User
export const logout = () => {
  return {
    type: LOGOUT_SUCCESS,
  };
};

// CLEAR DATA
export const cleardata = () => {
  return {
    type: CLEAR_DATA,
  };
};

// Setup config/headers and token
export const tokenConfig = (getState) => {
  // Get token from local storage
  const token = getState().auth.token;

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  if (token) {
    config.headers["Authorization"] = `${token}`;
  }
  return config;
};
