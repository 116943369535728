import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { clearErrors } from "../../store/actions/errorAction";
import { getFoundationQuestion } from "../../store/actions/foundationAssessmentAction";
import { postScore, getFoundationScore } from "../../store/actions/foundationScoreAction";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import '../../css/App.css';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {getMasterScore} from "../../store/actions/masterScoreAction";


const useStyles = theme => ({
    roots: {
        flexGrow: 1,
        margin: theme.spacing(0, 3),
        marginBottom: theme.spacing(1)
    },
    contentHeader: {
        paddingTop: theme.spacing(20),
        paddingBottom: theme.spacing(5),

    },
    papers: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        paddingBottom: theme.spacing(5),
        marginTop: theme.spacing(4)
    },

    about: {
        paddingTop: theme.spacing(7),
    },
    mainHeading: {
        fontSize: "1.7em",
        color: "#474747"
    },
    heading: {
        fontSize: "1.5em",
        paddingLeft: theme.spacing(3),
        color: "#fff"
    },
    span: {
        paddingTop: theme.spacing(5),
    },
    courses: {
        fontSize: "1.2em",
        color: "#474747"
    },
    header3: {
        position: 'relative',
        marginTop: "-45px",
        backgroundColor: "#f44336",
        width: "100%"
    },
    headerText: {
        padding: theme.spacing(2.5, 0),
        color: "#474747"
    },

    headingSpace: {
        padding: theme.spacing(2, 0)
    },
    title: {
        marginBottom: theme.spacing(8)
    },
    container: {
        maxHeight: 440,
        maxWidth: "50em",

    },
    fab: {
        color: "#fff",
        fontSize: "2em",
        position: "relative",
        left: "10%",
        top: "30%"
    },
    header5: {
        position: 'relative',
        marginTop: "-50px",
        backgroundColor: "#f44336",
        width: "70px",
        height: "70px"
    },
    table: {
        minWidth: 650,
    }

});







class MasterFailedQuestions extends Component {
    state = {

    };


    componentDidMount() {
    }


    componentWillMount() {
        this.props.getMasterScore();
    };

    componentWillUnmount() {

    };



    render() {
        // const classes = useStyles();
        const { classes } = this.props;
        const { question, id, userAnswer, options, number, min, sec, answer, } = this.state;
        let counter = 1;

        return (
            <div className={classes.roots}>
                <Grid container spacing={3} >
                    <Grid item xs={12} sm={12} md={12}>

                        <Paper className={classes.papers}>
                            <div >
                                {
                                    this.props.foundationScore.data.failedQuestions != null?

                                        this.props.foundationScore.data.failedQuestions.map((data)=>{

                                           return <Grid style={{"marginTop":"20px"}}  container spacing={3}>
                                                <Grid item xs={12} md={12} sm={12}>
                                                        <div key={id}>
                                                            <Typography id="unselectable" >{counter++}. {data.question.question}</Typography>
                                                            <p size="md" id="unselectable" color="info" className={`options ${data.question.answer === "A" ? "selected" : null}`} >{data.question.optionA}
                                                            </p>
                                                            <p size="md" id="unselectable" color="info" className={`options ${data.question.answer === "B" ? "selected" : null}`} >{data.question.optionB}
                                                            </p>
                                                            <p size="md" id="unselectable" color="info" className={`options ${data.question.answer === "C" ? "selected" : null}`} >{data.question.optionC}
                                                            </p>
                                                            <p size="md" id="unselectable" color="info" className={`options ${data.question.answer === "D" ? "selected" : null}`} >{data.question.optionD}
                                                            </p>

                                                        </div>
                                                </Grid>
                                            </Grid>

                                    }):"Loading..."
                                }
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        )
    }
};

MasterFailedQuestions.propTypes = {
    auth: PropTypes.object.isRequired,
    error: PropTypes.object.isRequired,
    getFoundationScore: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    foundationAssessment: state.foundationAssessment,
    foundationScore: state.masterScore,
    error: state.error,
});

export default connect(
    mapStateToProps,
    { getMasterScore }
)(withStyles(useStyles)(withRouter(MasterFailedQuestions)));