// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/ndpr.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".main-content {\n    position: absolute;\n   width: 100%;\n    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);\n    min-height: 100vh;\n    border-radius: 0.3%;\n    background:\n    linear-gradient(to bottom, #f8f7f7d0 , #0a0a0ad0 ),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")no-repeat center center;\n      background-size: cover; \n      /* min-height: 100vh;   */\n}\n\n.text-fields{\n    padding: 10px 0;\n}\n.btns{\n    margin: 0 auto;\n    padding: 25px 0 10px 0;\n}\n.form-hold{\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%) !important;\n    width: 100%;\n    padding: 0 20px;\n}", ""]);
// Exports
module.exports = exports;
