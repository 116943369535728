import React, { useEffect, useState } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { Alert } from 'reactstrap';
import {
    withStyles,
    makeStyles,
} from '@material-ui/core/styles';
import logo from "../../assets/logo.png";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import '../../css/App.css';
import { login} from '../../store/actions/authAction';
import { clearErrors } from "../../store/actions/errorAction";
import CircularProgress from '@material-ui/core/CircularProgress';
import "./Auth.css";


const ValidationTextField = withStyles({
    root: {
        '& input:valid + fieldset': {
            borderColor: 'green',
            borderWidth: 2,
        },
        '& input:invalid + fieldset': {
            borderColor: 'red',
            borderWidth: 2,
        },
        '& input:valid:focus + fieldset': {
            borderLeftWidth: 6,
            padding: '4px !important',
        },
    },
})(TextField);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        margin: 'auto',
        maxWidth: 500,
        borderRadius: 7
    },
}));

const Login = (props) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [loggedIn, setLoggedIn] = useState({
        email: "",
        password: ""
    });
    const { email, password } = loggedIn;
    const [initialized, setInitialized] = useState(false);
    const [msg, setMsg] = useState('');
    const [isloading, setIsloading] = useState(true);
    const [errs, setErrs] = useState({
        email: [],
        password: []
    });
    const { location, history, error } = props;
    const { state } = location;

    useEffect(() => {
        if (props.auth.isLoading) {
            setIsloading(true);
        }else{
            if (props.auth.isAuthenticated && props.auth.token !== null) {
                setIsloading(false)
                if(state !== null && state !== undefined){
                    props.history.push(state.from.pathname)    
                }
                else{
                    props.history.push("/dashboard") 
                }
            }else{
                if(!initialized){
                setIsloading(false)
                props.history.push("/login");
                }
                
            }
            }
            if (props.error.msg.message) {
                if(props.error.msg.message !== "Unauthorized, Token not found"){
                setMsg(props.error.msg.message);  
                }
            }
    

    }, [props.auth, state, initialized, isloading]);


    const change = (e, validators) => {
        const { name, value } = e.target;
        let targets = { ...loggedIn, [name]: value }
        setLoggedIn(targets)
        setMsg("");

        handleValidation(e.target, validators)

    };

    const handleValidation = (target, validators) => {
        validators.forEach(validation => {
            const result = validation(target.value)
            const errors = errs[target.name]

            if (result.valid) {
                if (errors.includes(result.message)) {
                    setErrs(prevState => ({
                        ...prevState,
                        [target.name]: errors.filter(error => error !== result.message)
                    }))
                }
            } else {
                if (!errors.includes(result.message)) {
                    setErrs(prevState => ({
                        ...prevState,
                        [target.name]: [...errors, result.message]
                    }))
                }
            }
        })
    }

    const noBlanks = (value) => {
        return {
            valid: value.replace(/\s+/g, "").length > 0,
            message: "Field cannot be blank"
        }
    };

    const submitForm = e => {
        e.preventDefault();
        if (!error.msg.message) {
            (state !== undefined && state !== null) ? history.push(state.from.pathname) : history.push("/dashboard")
            setOpen(!open);
        }
        props.login(loggedIn);
        props.clearErrors();
    };

    useEffect(() => {
        return () => {
            props.clearErrors();
        }
    }, [])

    return (
        <div className={classes.root}>
            {isloading ?
            <CircularProgress disableShrink />:
           
            <div className="main-content">
                <div className="icon_user">
                    <img src={logo} alt="logo"/>
                </div>
                {msg ? <Alert color="danger" style={{ textAlign: "center" }}>{msg}</Alert> : ""}
                
                <div className="form-hold">
                    <Paper className={classes.paper}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>

                                <form onSubmit={submitForm} action="POST" >

                                    <div className="text-fields">
                                        <ValidationTextField
                                            id="validation-outlined-input"
                                            label="Email"
                                            color="primary"
                                            fullWidth
                                            margin="normal"
                                            placeholder='Email Address'
                                            onChange={e => change(e, [noBlanks])}
                                            value={email}
                                            name="email"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            required
                                            variant="outlined"
                                            type="email"
                                        />
                                    </div>
                                    <div>
                                        <ValidationTextField
                                            id="outlined-full-width"
                                            label="Password"
                                            fullWidth
                                            margin="normal"
                                            placeholder='Password'
                                            onChange={e => change(e, [noBlanks])}
                                            value={password}
                                            name="password"
                                            type="password"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                            required
                                        />
                                    </div>
                                    <div className="btns">
                                        <Button type="submit" className="submit" id="btnId" positive size="huge">Login</Button>
                                    </div>

                                </form>

                                <div className="forgot">
                                    <NavLink to="/forgotpassword" >Forgot Password?</NavLink>
                                </div>


                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            </div>
             }
        </div>
    )
}

// PropTypes
login.propTypes = {
    error: PropTypes.object.isRequired,
    login: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    clearErrors: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    error: state.error,
    auth: state.auth
});

export default connect(
    mapStateToProps,
    { login, clearErrors }
)(withRouter(Login));