import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getFoundationScore } from "../../store/actions/foundationScoreAction";
import { generateFaCertificate } from "../../store/actions/foundationAssessmentAction";
import { generateMaCertificate } from "../../store/actions/masterAssessmentAction";
import { loadingToggleAction } from "../../store/actions/masterAssessmentAction";
import { getMasterScore } from "../../store/actions/masterScoreAction";
import { clearErrors } from "../../store/actions/errorAction";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import Typography from "@material-ui/core/Typography";
import AssessmentIcon from "@material-ui/icons/Assessment";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Icon from "@material-ui/core/Icon";
import Divider from "@material-ui/core/Divider";
import "./Dashboard.css";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  roots: {
    flexGrow: 1,
    margin: theme.spacing(0, 0),
  },
  contentHeader: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(5),
  },
  paper: {
    padding: theme.spacing(0, 8),
    paddingBottom: theme.spacing(10),
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(4),
  },
  papers: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    paddingBottom: theme.spacing(5),
    // margin: theme.spacing(20, 2)
  },
  paper3: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  text: {
    color: "red",
  },
  about: {
    paddingTop: theme.spacing(7),
  },
  abouts: {
    paddingTop: theme.spacing(1),
  },
  abouttext: {
    fontSize: "1.2em",
  },
  mainHeading: {
    fontSize: "1.7em",
  },
  heading: {
    fontSize: "1.5em",
    paddingLeft: theme.spacing(3),
    color: "#fff",
  },
  heading1: {
    color: "#ff9800",
    fontSize: "1.3em",
  },
  heading2: {
    fontSize: "1.3em",
  },
  span: {
    paddingTop: theme.spacing(5),
  },
  courses: {
    fontSize: "1.2em",
  },
  fab: {
    color: "#fff",
    fontSize: "3em",
    position: "relative",
    left: "20%",
    top: "25%",
  },
  header: {
    position: "relative",
    marginTop: "-40px",
    backgroundColor: "#ff9800",
    width: "100%",
  },
  header2: {
    position: "relative",
    marginTop: "-40px",
    backgroundColor: "#9c27b0",
    width: "100%",
  },
  header3: {
    position: "relative",
    marginTop: "-40px",
    backgroundColor: "#4caf50",
    width: "100%",
  },
  headerText: {
    padding: theme.spacing(2.5, 0),
    color: "#474747",
  },
  scores: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
  },
  score2: {
    marginBottom: theme.spacing(8),
  },
  title: {
    marginBottom: theme.spacing(8),
  },
  scoreDiv: {
    marginBottom: theme.spacing(6),
  },
  headingSpace: {
    padding: theme.spacing(2, 0),
  },
  headingSpace2: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(3),
  },
  header4: {
    position: "relative",
    marginTop: "-40px",
    backgroundColor: "#ff9800",
    width: "70px",
    height: "70px",
  },
  header5: {
    position: "relative",
    marginTop: "-40px",
    backgroundColor: "#4caf50",
    width: "70px",
    height: "70px",
  },
  header6: {
    position: "relative",
    marginTop: "-40px",
    backgroundColor: "#f44336",
    width: "70px",
    height: "70px",
  },
  header7: {
    position: "relative",
    marginTop: "-40px",
    backgroundColor: "#00bbff",
    width: "70px",
    height: "70px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Dashboard = (props) => {
  const classes = useStyles();
  const [initialized, setInitialized] = useState(false);
  const { data } = props.foundationScore;
  const { masterScore } = props;
  const dispatch = useDispatch();

  const handleFaDownloadCertificate = () => {
    dispatch(loadingToggleAction(true));

    // Call the generateFaCertificate function and pass the token from props
    props.generateFaCertificate(props.auth.token);
    // setIsLoading(false);
  };
  const handleMaDownloadCertificate = async () => {
    dispatch(loadingToggleAction(true));
    // Call the generateFaCertificate function and pass the token from props
    await props.generateMaCertificate(props.auth.token);
    // setIsLoading(false);
  };
  useEffect(() => {
    if (!initialized) {
      props.getFoundationScore();
      props.getMasterScore();
      setInitialized(true);
    }
  }, [initialized, props]);
  return (
    <div className={classes.roots}>
      {props.showLoading && (
        <>
          <Backdrop className={classes.backdrop} open>
            <CircularProgress color="inherit" />
          </Backdrop>
        </>
      )}
      <div>
        <div className={classes.title}>
          {/* <Typography paragraph className={classes.mainHeading}>Dashboard Overview</Typography>
                    <Divider /> */}
        </div>

        <Grid container spacing={5} className={classes.score2}>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Paper className={classes.paper3}>
              <Grid container>
                <Grid item md={8} sm={8} xs={8}>
                  <Paper className={classes.header4}>
                    <Icon
                      className="fa fa-check"
                      style={{
                        color: "#fff",
                        fontSize: "3em",
                        position: "relative",
                        left: "20%",
                        top: "25%",
                      }}
                    />
                  </Paper>
                </Grid>
                <Grid item md={4} sm={4} xs={4}>
                  <Typography className="score-div" variant="h3">
                    65%
                  </Typography>
                </Grid>
              </Grid>
              <Grid item md={12}>
                <div className={classes.headingSpace2}>
                  <Divider />
                </div>
                <Typography paragraph style={{ textAlign: "center" }}>
                  Passmark
                </Typography>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Paper className={classes.paper3}>
              <Grid container>
                <Grid item md={8} sm={8} xs={8}>
                  <Paper className={classes.header5}>
                    <AssessmentIcon className={classes.fab} />
                  </Paper>
                </Grid>
                <Grid item md={4} sm={4} xs={4}>
                  <Typography className="score-div" variant="h3">
                    40
                  </Typography>
                </Grid>
              </Grid>
              <Grid item md={12}>
                <div className={classes.headingSpace2}>
                  <Divider />
                </div>
                <Typography paragraph style={{ textAlign: "center" }}>
                  Questions
                </Typography>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Paper className={classes.paper3}>
              <Grid container>
                <Grid item md={8} sm={8} xs={8}>
                  <Paper className={classes.header6}>
                    <AssignmentIcon className={classes.fab} />
                  </Paper>
                </Grid>
                {props.auth.user.data.fA && props.auth.user.data.mA ? (
                  <Grid item md={4} sm={4} xs={4}>
                    <Typography className="score-div" variant="h3">
                      2
                    </Typography>
                  </Grid>
                ) : (
                  <Grid item md={4} sm={4} xs={4}>
                    <Typography className="score-div" variant="h3">
                      1
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Grid item md={12}>
                <div className={classes.headingSpace2}>
                  <Divider />
                </div>
                <Typography paragraph style={{ textAlign: "center" }}>
                  Assessment
                </Typography>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Paper className={classes.paper3}>
              <Grid container>
                <Grid item md={7} sm={7} xs={7}>
                  <Paper className={classes.header7}>
                    <Icon
                      className="fa fa-repeat"
                      style={{
                        color: "#fff",
                        fontSize: "3em",
                        position: "relative",
                        left: "20%",
                        top: "25%",
                      }}
                    />
                  </Paper>
                </Grid>
                <Grid item md={5} sm={5} xs={5}>
                  <Typography className="score-div" variant="h3">
                    30 days
                  </Typography>
                </Grid>
              </Grid>
              <Grid item md={12}>
                <div className={classes.headingSpace2}>
                  <Divider />
                </div>
                <Typography paragraph style={{ textAlign: "center" }}>
                  ReTake Assessment
                </Typography>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <Paper className={classes.papers}>
              <Paper className={classes.header3}>
                <div className={classes.headerText}>
                  <Typography paragraph className={classes.heading}>
                    Hi, {props.auth.user.data.firstname}
                  </Typography>
                </div>
              </Paper>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="flex-start"
              >
                <Paper className={classes.header5}>
                  <Tooltip title="Edit Profile">
                    <NavLink to="/edit-profile">
                      <EditIcon className={classes.fab} />
                    </NavLink>
                  </Tooltip>
                </Paper>
              </Grid>

              <div className={classes.abouts}>
                <Typography paragraph className={classes.abouttext}>
                  21 Search Ltd (a recruitment, trainings and certifications
                  company) and Taxaide Technologies Ltd (a DPCO and technology
                  development company) have established the NDPR Academy
                  Assessment® (www.ndpracademy.taxit.com.ng) with the aim of
                  providing NITDA-approved certiﬁcations.{" "}
                </Typography>
              </div>
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={3} className={classes.scores}>
          <Grid
            item
            lg={6}
            md={12}
            xs={12}
            sm={12}
            className={classes.scoreDiv}
          >
            <Paper elevation={2} className={classes.papers}>
              <Paper className={classes.header2}>
                <div className={classes.headerText}>
                  <Typography paragraph className={classes.heading}>
                    Assessment
                  </Typography>
                </div>
              </Paper>
              <div className="score-content">
                <div>
                  <Grid container spacing={3} className={classes.headingSpace}>
                    <Grid item md={4} xs={4} sm={4}>
                      <Typography paragraph className={classes.heading2}>
                        S/N
                      </Typography>
                    </Grid>
                    <Grid item md={4} xs={4} sm={4}>
                      <Typography paragraph className={classes.heading2}>
                        {" "}
                        Course
                      </Typography>
                    </Grid>
                    <Grid item md={4} xs={4} sm={4}>
                      <Typography paragraph className={classes.heading2}>
                        Control
                      </Typography>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className={classes.headingSpace}>
                    {props.auth.user.data.fA ? (
                      <Fragment>
                        <Grid
                          item
                          md={4}
                          xs={4}
                          sm={4}
                          className={classes.headingSpace}
                        >
                          <Typography>1.</Typography>
                        </Grid>
                        <Grid
                          item
                          md={4}
                          xs={4}
                          sm={4}
                          className={classes.headingSpace}
                        >
                          <Typography paragraph className={classes.courses}>
                            Foundation
                          </Typography>
                        </Grid>

                        <Grid item md={4} xs={4} sm={4}>
                          <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={() =>
                              props.history.push("/foundation-instructions")
                            }
                          >
                            Start
                          </Button>
                        </Grid>
                      </Fragment>
                    ) : (
                      ""
                    )}

                    {props.auth.user.data.mA ? (
                      <Fragment>
                        <Grid
                          item
                          md={4}
                          xs={4}
                          sm={4}
                          className={classes.headingSpace}
                        >
                          <Typography>2.</Typography>
                        </Grid>
                        <Grid item md={4} xs={4} sm={4}>
                          <Typography paragraph className={classes.courses}>
                            Practitioner
                          </Typography>
                        </Grid>

                        <Grid item md={4} xs={4} sm={4}>
                          {/* {data.score ? (
                            <>
                              {data.score[0].score > 65 ? (
                                <></>
                              ) : (
                                <> */}
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    onClick={() =>
                                      props.history.push("/master-instructions")
                                    }
                                  >
                                    Start
                                  </Button>
                                {/* </>
                              )}
                            </>
                          ) : (
                            <></>
                          )} */}
                        </Grid>
                      </Fragment>
                    ) : (
                      ""
                    )}
                  </Grid>
                </div>
              </div>
              <div></div>
            </Paper>
          </Grid>
          <Grid item lg={6} md={12} xs={12} sm={12}>
            <Paper elevation={2} className={classes.papers}>
              <Paper className={classes.header}>
                <div className={classes.headerText}>
                  <Typography paragraph className={classes.heading}>
                    Score
                  </Typography>
                </div>
              </Paper>
              <div className="score-content">
                <div>
                  <Grid container spacing={3} className={classes.headingSpace}>
                    <Grid item md={4} xs={4} sm={4}>
                      <Typography paragraph className={classes.heading1}>
                        Course
                      </Typography>
                    </Grid>
                    <Grid item md={4} xs={4} sm={4}>
                      <Typography paragraph className={classes.heading1}>
                        {" "}
                        Score
                      </Typography>
                    </Grid>
                    <Grid item md={4} xs={4} sm={4}>
                      <Typography paragraph className={classes.heading1}>
                        {" "}
                        Status
                      </Typography>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className={classes.headingSpace}>
                    {props.auth.user.data.fA ? (
                      <Fragment>
                        {data.score ? (
                          <Fragment>
                            <Grid item md={4} xs={4} sm={4}>
                              <Typography paragraph className={classes.courses}>
                                Foundation{" "}
                              </Typography>
                            </Grid>
                            <Grid item md={4} xs={4} sm={4}>
                              <Typography
                                paragraph
                                className={classes.abouttext}
                              >
                                {data.score[0].score}%
                              </Typography>
                            </Grid>
                            <Grid item md={4} xs={4} sm={4}>
                              <Typography
                                paragraph
                                className={classes.abouttext}
                              >
                                {data.score[0].score < 65 ? (
                                  <span style={{ color: " red" }}>
                                    Failed{" "}
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      size="small"
                                      onClick={() =>
                                        props.history.push("/foundation-failed")
                                      }
                                    >
                                      Review
                                    </Button>
                                  </span>
                                ) : (
                                  <>
                                    <span style={{ color: "green" }}>
                                      Passed
                                    </span>
                                    <span
                                      style={{
                                        color: "green",
                                        marginLeft: "16px",
                                      }}
                                    >
                                      {" "}
                                      <FontAwesomeIcon
                                        icon={faDownload}
                                        onClick={() => {
                                          handleFaDownloadCertificate();
                                        }}
                                      />
                                    </span>{" "}
                                  </>
                                )}
                              </Typography>
                            </Grid>
                          </Fragment>
                        ) : (
                          ""
                        )}
                      </Fragment>
                    ) : (
                      ""
                    )}
                    <Divider />

                    {props.auth.user.data.mA ? (
                      <Fragment>
                        {masterScore.data.score ? (
                          <Fragment>
                            <Grid item md={4} xs={4} sm={4}>
                              <Typography paragraph className={classes.courses}>
                                Practitioner
                              </Typography>
                            </Grid>
                            <Grid item md={4} xs={4} sm={4}>
                              <Typography paragraph>
                                {masterScore.data.score[0].score}%
                              </Typography>
                            </Grid>
                            <Grid item md={4} xs={4} sm={4}>
                              <Typography
                                paragraph
                                className={classes.abouttext}
                              >
                                {masterScore.data.score[0].score < 65 ? (
                                  <span style={{ color: " red" }}>
                                    Failed{" "}
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      size="small"
                                      onClick={() =>
                                        props.history.push("/master-failed")
                                      }
                                    >
                                      Review
                                    </Button>
                                  </span>
                                ) : (
                                  <>
                                    <span style={{ color: "green" }}>
                                      Passed
                                    </span>
                                    <span
                                      style={{
                                        color: "green",
                                        marginLeft: "20px",
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faDownload}
                                        onClick={() => {
                                          handleMaDownloadCertificate();
                                        }}
                                      />
                                    </span>
                                  </>
                                )}
                              </Typography>
                            </Grid>
                          </Fragment>
                        ) : (
                          ""
                        )}
                      </Fragment>
                    ) : (
                      ""
                    )}
                  </Grid>
                </div>
              </div>
              <div></div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

Dashboard.propTypes = {
  error: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  foundationScore: PropTypes.object.isRequired,
  masterScore: PropTypes.object.isRequired,
  getFoundationScore: PropTypes.func.isRequired,
  getMasterScore: PropTypes.func.isRequired,
  generateFaCertificate: PropTypes.func.isRequired,
  generateMaCertificate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  error: state.error,
  foundationScore: state.foundationScore,
  masterScore: state.masterScore,
  showLoading: state.masterAssessment.showLoading,
});

export default connect(mapStateToProps, {
  clearErrors,
  getFoundationScore,
  generateFaCertificate,
  generateMaCertificate,
  getMasterScore,
  //loadingToggleAction,
})(Dashboard);
