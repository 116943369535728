import React, { useState, useEffect, Fragment } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Spinner, Alert} from "reactstrap";
import { getAllScore } from "../../store/actions/masterScoreAction";
import { clearErrors } from "../../store/actions/errorAction";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Icon from '@material-ui/icons/ImportExport';
import Tooltip from '@material-ui/core/Tooltip';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';

const useStyles = makeStyles((theme) => ({
    
    roots: {
        flexGrow: 1,
        margin: theme.spacing(5, 3),
    },
    contentHeader: {
        paddingTop: theme.spacing(20),
        paddingBottom: theme.spacing(5),

    },
    papers: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        paddingBottom: theme.spacing(5),
        marginTop: theme.spacing(4)
    },
    text: {
        color: "red"
    },
    about: {
        paddingTop: theme.spacing(7),
    },
    abouttext: {
        fontSize: "1.2em",
        color: "#474747"
    },
    mainHeading: {
        fontSize: "1.7em",
        color: "#474747"
    },
    heading: {
        fontSize: "1.5em",
        paddingLeft: theme.spacing(3),
        color: "#fff"
    },
    span: {
        paddingTop: theme.spacing(5),
    },
    courses: {
        fontSize: "1.2em",
        color: "#474747"
    },
    header3: {
        position: 'relative',
        marginTop: "-45px",
        backgroundColor: "#9c27b0",
        width: "100%"
    },
    headerText: {
        padding: theme.spacing(2.5, 0),
        color: "#474747"
    },

    headingSpace: {
        padding: theme.spacing(2, 0)
    },
    title: {
        marginBottom: theme.spacing(8)
    },
    container: {
        maxHeight: 440,
      },
      fab: {
        color: "#fff",
        fontSize: "3em",
        position: "relative",
        left: "15%",
        top: "30%"
    },
    header5: {
        position: 'relative',
        marginTop: "-50px",
        backgroundColor: "#9c27b0",
        width: "70px",
        height: "70px"
    },

}));

const MasterScores = (props) => {
    const classes = useStyles();
    const { scores } = props.masterScore;
    // const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);
    const scorePages = [];

    useEffect(() => {
        setTimeout(() => {
            props.getAllScore();
          }, 2000); 
    }, []);

    // GET CURRENT POST
    // const indexOfLastPost = currentPage * postsPerPage;
    // const indexOfFirstPost = indexOfLastPost - postsPerPage;
    // const currentScore = scores.score ? scores.score.users.slice(indexOfFirstPost, indexOfLastPost) : scores;
    const currentScores = scores.score ? scores.score.users : scores;
    const totalScore = scores.score ? Math.ceil(scores.score.users.length/postsPerPage) : scores ;

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };
   
    for (let i =1; i <= totalScore; i++){
        scorePages.push(i);
    };

    // Change Page
    // const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const exportCSV = () => {
        let csvRow = [];
        let sheet = [['id', 'UserId', 'First Name', 'Last Name', 'Email', 'Score']];
        let datas = scores.score.users ;
        datas.forEach((data, i) => {
            sheet.push([i, data.id, data.firstname, data.lastname, data.email, data.MasterScores[0].score])
        });

        sheet.forEach(rows => {
            csvRow.push(rows.join(","))
        });
        
        let csvString = csvRow.join("\n");
        let doc = document.createElement("a");
        doc.href = 'data:attachment/csv,' + csvString;
        doc.target = "_Blank";
        doc.download = "download.csv";
        document.body.appendChild(doc);
        doc.click();
        document.body.removeChild(doc)

    };

    return (
        <div className={classes.roots}>
        <div >
            <Grid container spacing={3} >
                <Grid item xs={12} sm={12} md={12}>
                    
                    <Paper className={classes.papers}>
                        <Paper className={classes.header3}>
                            <div className={classes.headerText}>
                                <Typography paragraph className={classes.heading}>
                                 Master Scores List
                                </Typography>
                            </div>
                        </Paper>
                        {scores.score ? 
                        <Grid container
                                direction="row"
                                justify="flex-end"
                                alignItems="flex-start" >
                                <Paper className={classes.header5}>
                                    <Tooltip title="Export to Excel">
                                    
                    {/* <Button  content='Export to Excel' /> */}
                    <Icon className={classes.fab} onClick={exportCSV}/>
                                       
                                    </Tooltip>
                                </Paper>
                            </Grid>
                            : ""
                        }
                    {scores.message ?<Alert>{scores.message.toUpperCase() }</Alert> : <Alert>No Scores Available Yet</Alert> }
            {scores.score ? 
                        <TableContainer className={classes.container}>
                        <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                        <TableRow>
                            <TableCell style={{ minWidth: 170}}>id</TableCell>
                            <TableCell style={{ minWidth:  170 }}>First Name</TableCell>
                           <TableCell style={{ minWidth: 170 }}>Last Name</TableCell>
                           <TableCell style={{ minWidth: 170 }}>Email</TableCell>
                           <TableCell style={{ minWidth: 170 }}>Score</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody >
                    {currentScores.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((score, i) => {
              return (
                        // {currentScore.map((score, i) => 
                          <Fragment key={i}>
                            <TableRow hover role="checkbox" tabIndex={-1} >
                                 <TableCell>{score.id}</TableCell> 
                                <TableCell>{score.firstname}</TableCell>
                                <TableCell>{score.lastname}</TableCell>
                                <TableCell>{score.email}</TableCell>
                                <TableCell>{score.MasterScores ? score.MasterScores[0].score : ""}</TableCell>
                            </TableRow>
                            </Fragment>       
                        // )}
                        )})}
                        </TableBody>
                        </Table> </TableContainer>:<div id="spinner">
                                                <Spinner type="grow" color="primary" />
                                                <Spinner type="grow" color="secondary" />
                                                <Spinner type="grow" color="success" />
                                                <Spinner type="grow" color="danger" />
                                                <Spinner type="grow" color="warning" />
                                                <Spinner type="grow" color="info" />
                                                <Spinner type="grow" color="light" />
                                                <Spinner type="grow" color="dark" />
                                            </div>
                        }
                        {/* <div style={{marginTop: '30px'}}> 
                <nav>
                    <ul className="pagination">
                        {scorePages.map(number => (
                            <li key={number} className="page-item">
                            <NavLink  onClick={() => paginate(number)} href="#" className="page-link">{number}</NavLink>
                            </li>
                        ))}
                    </ul>
                </nav>
                </div> */}
                <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={scores.score ? scores.score.users.length: ""}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
               </Paper>
                </Grid>
                </Grid>
                     
            </div>
        </div>

    )
};

MasterScores.propTypes = {
    auth: PropTypes.object.isRequired,
    masterScore: PropTypes.object.isRequired,
    error: PropTypes.object.isRequired,
    clearErrors: PropTypes.func.isRequired,
    getAllScore: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    error: state.error,
    masterScore: state.masterScore
});

export default connect(
    mapStateToProps,
    { clearErrors, getAllScore }
)(MasterScores);
