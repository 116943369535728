import { GET_QUESTIONS, CLEAR_FOUNDATION_QUESTIONS, POST_FOUNDATION_QUESTIONS, FOUNDATION_QUESTIONS_LOADING} from "../actions/types";

const initialState = {
    questionnaire: [],  
    loading: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case FOUNDATION_QUESTIONS_LOADING:
            return {
                ...state,
                loading: true
            }
        case GET_QUESTIONS:
            return {
                ...state,
                questionnaire: action.payload,
                loading: false
            }
        case POST_FOUNDATION_QUESTIONS:
            return {
                ...state,
                ...action.payload
            }
        case CLEAR_FOUNDATION_QUESTIONS:
            return {
                ...initialState
            }
       
        default:
            return state;
    }
}