import {
  GET_QUESTIONS,
  CLEAR_MASTER_QUESTIONS,
  POST_MASTER_QUESTIONS,
  MASTER_QUESTIONS_LOADING,
  SHOW_LOADING,
  DOWNLOAD_MASTER_CERTIFICATE,
  DOWNLOAD_FOUNDATION_CERTIFICATE,
} from "../actions/types";

const initialState = {
  questionnaire: [],
  loading: false,
  showLoading: false,
  payload:{}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case MASTER_QUESTIONS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_QUESTIONS:
      return {
        ...state,
        questionnaire: action.payload,
        loading: false,
      };
    case POST_MASTER_QUESTIONS:
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR_MASTER_QUESTIONS:
      return {
        ...initialState,
      };
    case SHOW_LOADING:
      return {
        ...state,
        showLoading: action.payload,
      };
    case DOWNLOAD_MASTER_CERTIFICATE:
      return {
        ...state,
        payload: action.payload,
      };
    case DOWNLOAD_FOUNDATION_CERTIFICATE:
      return {
        ...state,
        payload: action.payload,
      };
    default:
      return state;
  }
}
