import React from "react";
import FoundationScores from "./FoundationScores";
import MasterScores from "./MasterScores";
import Grid from '@material-ui/core/Grid';


const Scores = () => {
    return (

 <Grid container spacing={3} > 
    <Grid item xs={12} sm={12} md={12}>
    <FoundationScores />
    <MasterScores />
    </Grid>
    </Grid>

    )
};

export default Scores;