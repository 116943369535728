import React from "react";
import {Link} from "react-router-dom";
import {NavLink} from "reactstrap";
import { Button} from 'semantic-ui-react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import "./Instructions.css";


const useStyles = makeStyles((theme) => ({
    roots: {
        flexGrow: 1,
        margin: theme.spacing(0, 3)
    },
    contentHeader: {
        paddingTop: theme.spacing(20),
        paddingBottom: theme.spacing(5),

    },
    papers: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        paddingBottom: theme.spacing(5),
        marginTop: theme.spacing(4)
    },
    text: {
        color: "red"
    },
    about: {
        paddingTop: theme.spacing(7),
    },
    abouttext: {
        fontSize: "1.2em",
        color: "#474747"
    },
    mainHeading: {
        fontSize: "1.7em",
        color: "#474747"
    },
    heading: {
        fontSize: "1.5em",
        paddingLeft: theme.spacing(3),
        color: "#fff"
    },
    span: {
        paddingTop: theme.spacing(5),
    },
    courses: {
        fontSize: "1.2em",
        color: "#474747"
    },
    header3: {
        position: 'relative',
        marginTop: "-45px",
        backgroundColor: "#f44336",
        width: "100%"
    },
    headerText: {
        padding: theme.spacing(2.5, 0),
        color: "#474747"
    },
   
    headingSpace: {
        padding: theme.spacing(2, 0)
    },
    title: {
        marginBottom: theme.spacing(8)
    },

}));

const Instructions = () => {
    const classes = useStyles();
    return (
        <div className={classes.roots}>
        <div >
            <Grid container spacing={3} >
                <Grid item xs={12} sm={12} md={12}>
                <div className={classes.title}>
                            
                        </div>
                <Paper className={classes.papers}>
                            <Paper className={classes.header3}>
                                <div className={classes.headerText}>
                                    <Typography paragraph className={classes.heading}>
                                    Certification Assessment Rules:
                                    </Typography>
                                </div>
                            </Paper>
                            <div className={classes.about}>
                                <Typography variant="h5">Please read the following carefully before proceeding: </Typography>
                            </div>
                            <div >
                            <ol>
                <li><Typography paragraph>You have chosen to undertake these Certification Assessment Questions (CAQs) with the view of being certified as knowledgeable in the 2019 Nigerian Data Protection Regulations. The CAQs have accordingly been set with this objective 
                    in mind.</Typography></li>
                <li><Typography paragraph>It is expected that you would have studied the NDPR Academy® Foundation Course Modules before your decision 
                        to take the CAQs. Accordingly, and to ensure the integrity of the CAQs and processes of the NDPR Academy®, 
                        you may only attempt the CAQs once every month.</Typography></li>
                <li><Typography paragraph>You are required to answer only 40 CAQs within 1 hour. Your 40 CAQs are randomly selected from a pool of CAQs certified 
                        by the NDPR Academy® Faculty. The order of your CAQs will always differ from time to time and from examinee to examinee.</Typography></li>
                <li><Typography paragraph>NDPR Academy® has designed the CAQs to be taken at your convenience and leisure, howbeit with the underlying 
                    principle that it is still an educational assessment. </Typography></li>
                <li><Typography paragraph>You are required to correctly score a minimum of 65% of your CAQs for NDPR Academy® to issue you with a NDPR Academy® Foundation Course Certificate.
                    While your CAQs score will immediately be made known to you upon completion of your CAQs, your NDPR Academy® Foundation 
                    Course Certificate will be sent to your registered email within 2 weeks of your successful completion of your CAQs</Typography></li>
                
            </ol>
            <Typography paragraph>Please direct all enquiries on any of the foregoing to:<NavLink href="https://certifications@ndpracademy.ng" target="_blank" className="ndpr">certifications@ndpracademy.ng</NavLink> </Typography >
            <Typography paragraph>When you are ready to start. Please click on the start button.</Typography >
            <Link to="/foundation-assessment" id="linker ">  <Button inverted color='red' id="start">Start</Button></Link>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>  
            </div>
       
            </div>
       
    )
};

export default Instructions;
