import React, { useEffect, useState } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { Alert } from "reactstrap"
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Form, Icon, Input, Label } from 'semantic-ui-react';
import '../../css/App.css';
import { resetPassword } from '../../store/actions/authAction';
import { clearErrors } from "../../store/actions/errorAction";

const ResetPassword = (props) => {
    const [reset, setReset] = useState({
        password: "",
        confirmPassword: "",
    });
    const { confirmPassword, password } = reset;
    const [msg, setMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('')
    const [errs, setErrs] = useState({
        confirmPassword: [],
        password: []
    });


    useEffect(() => {
        
            if (props.error.msg.message) {
                if(props.error.msg.message !== "Unauthorized, Token not found"){
                    setMsg(props.error.msg.message);
                };
            }else{
                setMsg("")
            }

            if (props.auth.user.success) {
                setSuccessMsg("SUCCESS, PLEASE LOGIN")
            }
        

    }, [props.auth, props.error.msg])

    const change = (e, validators) => {
        const { name, value } = e.target;
        let targets = { ...reset, [name]: value }
        setReset(targets)
        setMsg("");

        handleValidation(e.target, validators)

    };

    const handleValidation = (target, validators) => {
        validators.forEach(validation => {
            const result = validation(target.value)
            const errors = errs[target.name]

            if (result.valid) {
                if (errors.includes(result.message)) {
                    setErrs(prevState => ({
                        ...prevState,
                        [target.name]: errors.filter(error => error !== result.message)
                    }))
                }
            } else {
                if (!errors.includes(result.message)) {
                    setErrs(prevState => ({
                        ...prevState,
                        [target.name]: [...errors, result.message]
                    }))
                }
            }
        })
    }

    const noBlanks = (value) => {
        return {
            valid: value.replace(/\s+/g, "").length > 0,
            message: "Field cannot be blank"
        }
    };

    const submitForm = e => {
        e.preventDefault();
        setReset({
            confirmPassword: "",
            password: ""
        });

        props.resetPassword(reset, props.match.params.resetToken);
        props.clearErrors();

    }

    return (

        <div id="container">
            <div className="login_form">
                <div className="icon_user">
                    <Icon name="user" size="massive" color="green" />
                </div>

                <div className="login_hold">

                    <Form onSubmit={submitForm} action="POST" >
                        {msg ? <Alert color="danger" style={{ textAlign: "center" }}>{msg}</Alert> : ""}
                        {successMsg ? <Alert style={{ textAlign: "center" }}>{successMsg}</Alert> : ""}
                        <Form.Field>
                            <Input
                                placeholder='Password'
                                onChange={e => change(e, [noBlanks])}
                                value={password}
                                name="password"
                                type="password"
                                icon="lock"
                                iconPosition='left' />
                            {errs.password.length > 0 ?
                                <Label basic color='red' ribbon>
                                    {errs.password}
                                </Label>
                                : ""}
                        </Form.Field>
                        <Form.Field>
                            <Input
                                placeholder='Confirm Password'
                                onChange={e => change(e, [noBlanks])}
                                value={confirmPassword}
                                name="confirmPassword"
                                type="password"
                                icon="lock"
                                iconPosition='left' />
                            {errs.confirmPassword.length > 0 ?
                                <Label basic color='red' ribbon>
                                    {errs.confirmPassword}
                                </Label>
                                : ""}
                        </Form.Field>
                        <Button type="submit" className="submit" id="btnId" fluid positive >Submit</Button>
                    </Form>
                    <div className="forgot">
                        <NavLink to="/login" >Login?</NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}

// PropTypes
ResetPassword.propTypes = {
    error: PropTypes.object.isRequired,
    resetPassword: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    clearErrors: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    error: state.error,
    auth: state.auth
});

export default connect(
    mapStateToProps,
    { resetPassword, clearErrors }
)(withRouter(ResetPassword));