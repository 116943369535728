import {
  FINAL_MASTER_SCORE,
  SCORE_LOADING,
  GET_MASTER_SCORE,
  CLEAR_SCORE,
  GET_ALL_MASTER_SCORES,
  POST_MASTER_ANS,
  DOWNLOAD_MASTER_CERTIFICATE,
  DOWNLOAD_FOUNDATION_CERTIFICATE,
} from "./types";
import axios from "../../utils/axios-base";
import { returnErrorrs } from "./errorAction";
import { loadingToggleAction } from "./masterAssessmentAction";

export const setScoreLoading = () => {
  return {
    type: SCORE_LOADING,
  };
};

export const sendMasterCertficate = (id, token) => (dispatch) => {
  axios
    .post(
      `/api/user/masterscore/sendcertification`,
      { id },
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    )
    .then((res) => {
      dispatch(loadingToggleAction(false));
      dispatch({ type: DOWNLOAD_MASTER_CERTIFICATE, payload: res.data });
    })
    .catch((err) => {
      dispatch(loadingToggleAction(false));
      dispatch(returnErrorrs(err.response.data, err.response.status));
      return err;
    });
};

export const sendFoundationCertficate = (id, token) => (dispatch) => {
  axios
    .post(
      `/api/user/foundationscore/sendcertification`,
      { id },
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    )
    .then((res) => {
      dispatch(loadingToggleAction(false));
      dispatch({ type: DOWNLOAD_FOUNDATION_CERTIFICATE, payload: res.data });
    })
    .catch((err) => {
      dispatch(loadingToggleAction(false));
      if (err && err.response && err.response.data && err.response.data.message)
        dispatch(returnErrorrs(err.response.data, err.response.status));
      return err;
    });
};

export const postMasterScore = (score, token) => (dispatch) => {
  axios
    .post("/api/user/masterscore", score, {
      headers: {
        Authorization: `${token}`,
      },
    })
    .then((res) => {
      dispatch({
        type: FINAL_MASTER_SCORE,
        payload: res.data,
      });
      dispatch(getMasterScore());
    })
    .catch((err) =>
      dispatch(returnErrorrs(err.response.data, err.response.status))
    );
};

export const postAns = (answer) => (dispatch) => {
  dispatch({
    type: POST_MASTER_ANS,
    payload: answer,
  });
};

export const getMasterScore = () => (dispatch, getState) => {
  dispatch(setScoreLoading());
  axios
    .get("/api/user/masterscore", tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_MASTER_SCORE,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(returnErrorrs(err.response.data, err.response.status))
    );
};

export const getAllScore = () => (dispatch, getState) => {
  // dispatch(setScoreLoading());
  axios
    .get("/api/admin/masterscores", tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_ALL_MASTER_SCORES,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(returnErrorrs(err.response.data, err.response.status))
    );
};

export const clearMasterScore = () => {
  return {
    type: CLEAR_SCORE,
  };
};

// Setup config/headers and token
export const tokenConfig = (getState) => {
  // Get token from local storege
  const token = getState().auth.token;

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  if (token) {
    config.headers["Authorization"] = `${token}`;
  }
  return config;
};
