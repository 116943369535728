import React, { useState, useEffect } from 'react';
import {
    Col, Alert
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateDetails } from '../../store/actions/authAction';
import { clearErrors } from "../../store/actions/errorAction";
import isEmpty from "../../validation/is-empty";
import "../../css/App.css";
import "./Profile.css";
import { Input, Form, Label } from 'semantic-ui-react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({
    
    roots: {
        flexGrow: 1,
        margin: theme.spacing(0, 3)
    },
    contentHeader: {
        paddingTop: theme.spacing(20),
        paddingBottom: theme.spacing(5),

    },
    papers: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        paddingBottom: theme.spacing(5),
        marginTop: theme.spacing(4)
    },
    text: {
        color: "red"
    },
    about: {
        paddingTop: theme.spacing(7),
    },
    abouttext: {
        fontSize: "1.2em",
        color: "#474747"
    },
    mainHeading: {
        fontSize: "1.7em",
        color: "#474747"
    },
    heading: {
        fontSize: "1.5em",
        paddingLeft: theme.spacing(3),
        color: "#fff"
    },
    span: {
        paddingTop: theme.spacing(5),
    },
    courses: {
        fontSize: "1.2em",
        color: "#474747"
    },
    header3: {
        position: 'relative',
        marginTop: "-45px",
        backgroundColor: "#9c27b0",
        width: "100%"
    },
    headerText: {
        padding: theme.spacing(2.5, 0),
        color: "#474747"
    },

    headingSpace: {
        padding: theme.spacing(2, 0)
    },
    title: {
        marginBottom: theme.spacing(8)
    },

}));


const Profile = (props) => {
    const classes = useStyles();
    const [users, setUser] = useState({
        firstname: " ",
        lastname: " ",
        email: " ",
        organisation: "",
    });

    const [errs, setErrs] = useState({
        firstname: [],
        lastname: [],
        email: []

    });
    const [errMsg, setErrMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const { firstname, lastname, email, organisation } = users
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        setErrMsg('');
        setSuccessMsg("");
    }, [errMsg, successMsg]);

    useEffect(() => {
        if (!initialized) {
            if (props.auth.user) {
                const profile = props.auth.user.data;
                // if user doesnt exist make empty
                setUser({
                    firstname: !isEmpty(profile.firstname) ? profile.firstname : "",
                    lastname: !isEmpty(profile.lastname) ? profile.lastname : "",
                    email: !isEmpty(profile.email) ? profile.email : "",
                    organisation: !isEmpty(profile.organisation) ? profile.organisation : ""

                });
            }
        } else {
            if (props.error.msg.message) {
                setErrMsg(props.error.msg.message);
            } else {
                if (props.auth.update === true) {
                    setSuccessMsg("SUCCESSFUL UPDATE")
                }
            }
        }
        setInitialized(true);
    }, [initialized, props])

    const change = (e, validators) => {
        const { name, value } = e.target;
        let targets = { ...users, [name]: value }
        setUser(targets);
        setErrMsg("");
        setSuccessMsg("");

        handleValidation(e.target, validators)
    };

    const handleValidation = (target, validators) => {
        validators.forEach(validation => {
            const result = validation(target.value)
            const errors = errs[target.name]

            if (result.valid) {
                if (errors.includes(result.message)) {
                    setErrs(prevState => ({
                        ...prevState,
                        [target.name]: errors.filter(error => error !== result.message)
                    }))
                }
            } else {
                if (!errors.includes(result.message)) {
                    setErrs(prevState => ({
                        ...prevState,
                        [target.name]: [...errors, result.message]
                    }))
                }
            }
        })
    }

    const noBlanks = (value) => {
        return {
            valid: value.replace(/\s+/g, "").length > 0,
            message: "Field cannot be blank"
        }
    };

    const updateForm = e => {
        e.preventDefault();
        const user = { firstname, lastname, email }
        props.updateDetails(user, props.auth.token);
        props.clearErrors();
    };

    return (
        <div className={classes.roots}>
            <div >
                <Grid container spacing={3} >
                    <Grid item xs={12} sm={12} md={12}>
                        <div className={classes.title}>
                           
                        </div>
                        <Paper className={classes.papers}>
                            <Paper className={classes.header3}>
                                <div className={classes.headerText}>
                                    <Typography paragraph className={classes.heading}>
                                        Update Profile
                                    </Typography>
                                </div>
                            </Paper>
                            <div className={classes.about}>
                                {errMsg ? <Alert color="danger">{errMsg}</Alert> : ""}
                                {successMsg ? <Alert>{successMsg}</Alert> : ""}
                                <div>

                                    <Form onSubmit={updateForm}>
                                        <Form.Group >
                                            <Col md={2}>
                                                <label>First Name</label>
                                            </Col>

                                            <Col md={10}>
                                                <Input type='text' placeholder='First Name'
                                                    onChange={e => change(e, [noBlanks])}
                                                    value={firstname}
                                                    name="firstname"
                                                    icon="user"
                                                    iconPosition='left' fluid />
                                            </Col>

                                            {errs.firstname.length > 0 ?
                                                <Label basic color='red' pointing>
                                                    {errs.firstname}
                                                </Label>
                                                : ""}
                                        </Form.Group>
                                        <hr />
                                        <Form.Group >
                                            <Col md={2}>
                                                <label >Last Name</label>
                                            </Col>
                                            <Col md={10}>
                                                <Input type='text'
                                                    placeholder='Last Name'
                                                    onChange={e => change(e, [noBlanks])}
                                                    value={lastname}
                                                    name="lastname"
                                                    icon="user"
                                                    iconPosition='left' fluid width={100} />
                                            </Col>
                                            {errs.lastname.length > 0 ?
                                                <Label basic color='red' pointing>
                                                    {errs.lastname}
                                                </Label>
                                                : ""}
                                        </Form.Group>
                                        <hr />
                                        <Form.Group >
                                            <Col md={2}>
                                                <label>Email</label>
                                            </Col>

                                            <Col md={10}>
                                                <Input type='text'
                                                    placeholder='Email Address'
                                                    onChange={e => change(e, [noBlanks])}
                                                    value={email}
                                                    name="email"
                                                    icon="mail"
                                                    iconPosition='left' fluid />
                                            </Col>
                                            {errs.email.length > 0 ?
                                                <Label basic color='red' pointing>
                                                    {errs.email}
                                                </Label>
                                                : ""}
                                        </Form.Group>
                                        <hr />
                                        <Form.Group >
                                            <Col md={2}>
                                                <label >Organisation</label>
                                            </Col>
                                            <Col md={10}>
                                                <Input type='text'
                                                    placeholder='Organisation'
                                                    onChange={e => change(e, [noBlanks])}
                                                    value={organisation}
                                                    name="organisation"
                                                    icon="industry"
                                                    iconPosition='left' fluid disabled />
                                            </Col>
                                        </Form.Group>
                                        <Button variant="contained" style={{ marginTop: '20px', backgroundColor: "#9c27b0", color: "#fff" }} type="submit">Update Profile</Button>
                                    </Form>
                                   
                            </div>
                            </div>
                            </Paper>
                </Grid>
                </Grid>
                     
            </div>
        </div>
    )
}

Profile.propTypes = {
    error: PropTypes.object.isRequired,
    clearErrors: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    updateDetails: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    error: state.error
});

export default connect(
    mapStateToProps,
            { updateDetails, clearErrors}
)(Profile);
