import {
  GET_QUESTIONS,
  CLEAR_MASTER_QUESTIONS,
  POST_MASTER_QUESTIONS,
  MASTER_QUESTIONS_LOADING,
  DOWNLOAD_MASTER_CERTIFICATE,
  SHOW_LOADING,
} from "./types";
import axios from "../../utils/axios-base";
import { returnErrorrs } from "./errorAction";

export const getMasterQuestion = () => (dispatch, getState) => {
  dispatch(setQuestionLoading());
  axios
    .get("/api/masterassessment", tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_QUESTIONS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(returnErrorrs(err.response.data, err.response.status))
    );
};

export const postMasterQuestion = (questions, token) => (dispatch) => {
  dispatch(setQuestionLoading());
  axios
    .post("/api/masterassessment", questions, {
      headers: {
        Authorization: `${token}`,
      },
    })
    .then((res) => {
      dispatch({
        type: POST_MASTER_QUESTIONS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch(returnErrorrs(err.response.data, err.response.status))
    );
};

export const generateMaCertificate = (token) => (dispatch) => {
  axios
    .get("/api/user/master-certificate", {
      headers: {
        Authorization: `${token}`,
      },
      responseType: "blob", // Set the response type to blob to handle file data
    })
    .then((res) => {
      dispatch(loadingToggleAction(false));
      // Create a Blob from the response data
      const fileBlob = new Blob([res.data], { type: "application/pdf" });

      // Create a temporary URL for the Blob
      const url = window.URL.createObjectURL(fileBlob);

      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.download = "Certificate.pdf"; // Set the filename for the downloaded file
      link.click();

      // Clean up the temporary URL
      window.URL.revokeObjectURL(url);

      dispatch({
        type: DOWNLOAD_MASTER_CERTIFICATE,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(loadingToggleAction(false));
      dispatch(returnErrorrs(err.response.data, err.response.status));
    });
};
export const setQuestionLoading = () => {
  return {
    type: MASTER_QUESTIONS_LOADING,
  };
};

export const loadingToggleAction = (status) => {
  return {
    type: SHOW_LOADING,
    payload: status,
  };
};
export const clearMasterQuestions = () => {
  return {
    type: CLEAR_MASTER_QUESTIONS,
  };
};

// Setup config/headers and token
export const tokenConfig = (getState) => {
  // Get token from local storege
  const token = getState().auth.token;

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  if (token) {
    config.headers["Authorization"] = `${token}`;
  }
  return config;
};
