import React, { useState, useEffect } from 'react';
import { forgotPassword, cleardata } from "../../store/actions/authAction";
import { clearErrors } from "../../store/actions/errorAction";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert } from "reactstrap";
import { Button} from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import {
    withStyles,
    makeStyles,
} from '@material-ui/core/styles';
import logo from "../../assets/logo.png";
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const ValidationTextField = withStyles({
    root: {
        '& input:valid + fieldset': {
            borderColor: 'green',
            borderWidth: 2,
        },
        '& input:invalid + fieldset': {
            borderColor: 'red',
            borderWidth: 2,
        },
        '& input:valid:focus + fieldset': {
            borderLeftWidth: 6,
            padding: '4px !important',
        },
    },
})(TextField);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        margin: 'auto',
        maxWidth: 500,
        borderRadius: 7
    },
}));


const ForgotPassword = (props) => {
    const classes = useStyles();
    const [email, setEmail] = useState({
        email: ""
    });
    const [msg, setMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState("");
    const [initialized, setInitialized] = useState(false);

    const change = (e) => {
        const { name, value } = e.target;
        let targets = { ...email, [name]: value }
        setEmail(targets)
        setMsg("");

    };

    


    useEffect(() => {
            if (props.error.msg.message) {
                if(props.error.msg.message !== "Unauthorized, Token not found"){
                    setMsg(props.error.msg.message);
                } 

            }else{
                setMsg("") 
            }
            if (props.auth.email.message) {
                setSuccessMsg(props.auth.email.message);
            }

    }, [props.auth, props.error.msg]);
    

    const submit = (e) => {
        e.preventDefault();
        props.forgotPassword(email);
        props.clearErrors();
        setEmail({ email: "" })
    };

    useEffect(() => {
        return () => {
            props.cleardata();
            props.clearErrors();
        }
    }, [])

    return (
        <div className={classes.root}>
            <div className="main-content">
                <div className="icon_user">
                    <img src={logo} alt="logo"/>

                </div>
                <div className="form-hold">
                {msg ? <Alert color="danger" style={{ textAlign: "center" }}>{msg}</Alert> : ""}
                                    {successMsg ? <Alert style={{ textAlign: "center" }}>{successMsg.toUpperCase()}</Alert> : ""}
                    <Paper className={classes.paper}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="h4">Forgot Password</Typography>

                                <form onSubmit={submit} action="POST" >

                                    <div className="text-fields">
                                        <ValidationTextField
                                            id="validation-outlined-input"
                                            label="Email"
                                            fullWidth
                                            margin="normal"
                                            placeholder='Email Address'
                                            onChange={change}
                                            value={email.email}
                                            name="email"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            required
                                            variant="outlined"
                                            type="email"
                                        />

                                    </div>
                                    <div className="btns">
                                    <Button type="submit" className="submit" id="btnId" positive size="huge">Submit</Button>
                                    </div>
                                </form>
                                <div className="forgot">
                                    <NavLink to="/login" >Login</NavLink>
                                </div>

                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            </div>
        </div>
    )
};

// PropTypes
ForgotPassword.propTypes = {
    error: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    forgotPassword: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    error: state.error,
    auth: state.auth,
});

export default connect(
    mapStateToProps,
    { forgotPassword, clearErrors, cleardata }
)((ForgotPassword));