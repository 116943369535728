import { SCORE_LOADING, GET_MASTER_SCORE, FINAL_MASTER_SCORE, CLEAR_SCORE, GET_ALL_MASTER_SCORES, POST_MASTER_ANS } from "../actions/types";

const initialState = {
    data: [],
    scores: [],
    ans: [],
    loading: false
}

export default function (state = initialState, action) {
    switch (action.type) {
      case GET_MASTER_SCORE:
        return {
          ...state,
          data: action.payload,
          loading: false,
        };
      case GET_ALL_MASTER_SCORES:
        return {
          ...state,
          scores: action.payload,
          loading: false,
        };
      case POST_MASTER_ANS:
        return {
          ...state,
          ans: action.payload,
          loading: false,
        };
      case SCORE_LOADING:
        return {
          ...state,
          loading: true,
        };
      case FINAL_MASTER_SCORE:
        return {
          ...state,
          ...action.payload,
          loading: false,
        };
      case CLEAR_SCORE:
        return {
          ...initialState,
        };
      default:
        return state;
    }
}