import { SCORE_LOADING, GET_FOUNDATION_SCORE, FINAL_FOUNDATION_SCORE, CLEAR_SCORE, GET_ALL_FOUNDATION_SCORES, POST_FOUNDATION_ANS } from "../actions/types";

const initialState = {
    data: [],
    scores: [],
    ans: [],
    loading: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_FOUNDATION_SCORE:
            return {
                ...state,
                data:action.payload,
                loading: false
            }
        case GET_ALL_FOUNDATION_SCORES:
            return {
                ...state,
                scores: action.payload,
                loading: false
            }
        case POST_FOUNDATION_ANS:
            return {
                ...state,
                ans: action.payload,
                loading: false
            }
        case SCORE_LOADING:
            return {
                ...state,
                loading: true
            }
        case FINAL_FOUNDATION_SCORE:
            return {
                ...state,
                ...action.payload,
                loading: false
            }
        case CLEAR_SCORE:
            return {
                ...initialState
            }
        default:
            return state;
    }
}