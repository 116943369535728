import React, { Fragment, useState } from 'react';
import { NavLink as RRNavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Logout from "../auth/Logout";
import PropTypes from 'prop-types';
import { clearErrors } from "../../store/actions/errorAction";
import { makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ScoreIcon from '@material-ui/icons/Score';
import PeopleIcon from '@material-ui/icons/People';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AddPersonIcon from '@material-ui/icons/PersonAdd';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Hidden from '@material-ui/core/Hidden';
import Router from "../routes/Router";
import { NavLink } from 'reactstrap';
import logo from "../../assets/logo.png";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    color: "#474747"
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0
    },
    color: "#474747",
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3, 2),
  },
  drawerPaper: {
    color: "#474747",
    backgroundImage: 'linear-gradient(315deg, #000000 0%, #343434 74%)',
    width: drawerWidth,
  },
  drawerHeader: {
    marginTop: theme.spacing(5)
  }
  
}));


const DrawerComponent = (props) => {
  const { window } = props;
  const classes = useStyles();
  const { isAuthenticated, user } = props.auth;
  const container = window !== undefined ? () => window().document.body : undefined;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [open, setOpen] = React.useState(false);

  const getNavSets = () => {
    if (isAuthenticated)
      return user.data.isAdmin ? adminLinks : authLinks;
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const authLinks = (
    <Fragment >
      <List>
        <NavLink to="/dashboard" tag={RRNavLink} style={{ color: "#FFF" }}>
          <ListItem button>
            <ListItemIcon><DashboardIcon style={{ color: "#fff" }} /></ListItemIcon>
            <ListItemText primary={"Dashboard"} />
          </ListItem>
        </NavLink>

        <Logout />

      </List>

    </Fragment>
  );

  const adminLinks = (
    <Fragment >
      <List>
        <NavLink to="/dashboard" tag={RRNavLink} style={{ color: "#fff" }}>
          <ListItem button>
            <ListItemIcon><DashboardIcon style={{ color: "#fff" }} /></ListItemIcon>
            <ListItemText primary={"Dashboard"} />
          </ListItem>
        </NavLink>


        <NavLink to="/admin/new/client" tag={RRNavLink} style={{ color: "#fff" }}>
          <ListItem button>
            <ListItemIcon><AddPersonIcon style={{ color: "#fff" }} /></ListItemIcon>
            <ListItemText primary={" New Users"} />
          </ListItem>
        </NavLink>

        <NavLink to="/admin/list/clients" tag={RRNavLink} style={{ color: "#fff" }}>
          <ListItem button>
            <ListItemIcon><PeopleIcon style={{ color: "#fff" }} /></ListItemIcon>
            <ListItemText primary={"Users"} />
          </ListItem>
        </NavLink>

        <NavLink to="/admin/list/all-scores" tag={RRNavLink} style={{ color: "#fff" }}>
          <ListItem button>
            <ListItemIcon><ScoreIcon style={{ color: "#fff" }} /></ListItemIcon>
            <ListItemText primary={" Scores"} />
          </ListItem>
        </NavLink>

        <NavLink to="/admin/new-foundation-questions" tag={RRNavLink} style={{ color: "#fff" }}>
          <ListItem button>
            <ListItemIcon><LibraryAddIcon style={{ color: "#fff" }} /></ListItemIcon>
            <ListItemText primary={"Add Foundation Questions"} />
          </ListItem>
        </NavLink>

        <NavLink to="/admin/new-master-questions" tag={RRNavLink} style={{ color: "#fff" }}>
          <ListItem button>
            <ListItemIcon><LibraryAddIcon style={{ color: "#fff" }} /></ListItemIcon>
            <ListItemText primary={"Add Practitioner Questions"} />
          </ListItem>
        </NavLink>
        <Logout />
      </List>

    </Fragment>
  );
  return (
    <Fragment >
      {props.auth.token ?
        <div className={classes.root}>
          <CssBaseline />
          <nav aria-label="mailbox folders" className={classes.drawer}>
            <Hidden smUp implementation="css" >
              <AppBar position="fixed" className={classes.appBar}
                style={{ background: '#414141'}}
              >
                <Toolbar>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    className={classes.menuButton}
                  >
                    <MenuIcon style={{ color: '#fff'}} />
                  </IconButton>
                </Toolbar>
              </AppBar>
              <Drawer
                container={container}
                className={classes.drawer}
                variant="temporary"
                anchor="right"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                <div >
                  <IconButton >
                    <img src={logo} width="100px" alt="logo" />
                  </IconButton>
                </div>
                <Divider />
                {getNavSets()}
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                <div >
                  <IconButton >
                    <img src={logo} width="100px" alt="logo" />
                  </IconButton>
                </div>
                <Divider />
                {getNavSets()}
              </Drawer>
            </Hidden>
          </nav>
          
          <main className={classes.content}>         
            <Router />
          </main> 
        </div>
        : <Router />}
    </Fragment>
  );
};

DrawerComponent.propTypes = {
  auth: PropTypes.object.isRequired,
  foundationAssessment: PropTypes.object.isRequired,
  masterAssessment: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  foundationAssessment: state.foundationAssessment,
  masterAssessment: state.masterAssessment
});

export default connect(mapStateToProps,
  { clearErrors }
)(withRouter(DrawerComponent));
