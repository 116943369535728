import axios from "axios";

// Creates a base instance for all axios based request
const instance = axios.create({
  baseURL: process.env.REACT_APP_API,
  //"https://api.ndpacademy.taxit.com.ng/",
  //baseURL: "http://localhost:9000/",
});

export default instance;
