export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const USERS_LOADING = "USERS_LOADING";
export const FOUNDATION_QUESTIONS_LOADING = "FOUNDATION_QUESTIONS_LOADING ";
export const MASTER_QUESTIONS_LOADING = "MASTER_QUESTIONS_LOADING ";
export const USERS_LOADED = "USERS_LOADED";
export const QUESTION_SCORE = "QUESTION_SCORE";
export const QUESTION_LOADING = "QUESTION_LOADING";
export const GET_QUESTIONS = "GET_QUESTIONS";
export const POST_FOUNDATION_QUESTIONS = "POST_FOUNDATION_QUESTIONS";
export const POST_MASTER_QUESTIONS = "POST_MASTER_QUESTIONS";
export const FINAL_FOUNDATION_SCORE = "FINAL_FOUNDATION_SCORE";
export const FINAL_MASTER_SCORE = "FINAL_MASTER_SCORE";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT SUCCESS";
export const REG_SUCCESS = "REGISTER_SUCCESS";
export const REG_FAIL = "REG_FAIL";
export const PASSWORD_RESET_LINK_SUCCESS = "PASSWORD_RESET_LINK_SUCCESS";
export const PASSWORD_RESET_LINK_FAIL = "PASSWORD_RESET_LINK_FAIL";
export const REG_LINK_FAIL = "REG_LINK_FAIL";
export const SCORE_LOADING = "SCORE_LOADING";
export const GET_FOUNDATION_SCORE = "GET_FOUNDATION_SCORE";
export const GET_MASTER_SCORE = "GET_MASTER_SCORE";
export const GET_ALL_MASTER_SCORES = "GET_ALL_MASTER_SCORES";
export const GET_ALL_FOUNDATION_SCORES = "GET_ALL_FOUNDATION_SCORES";
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const CLEAR_FOUNDATION_QUESTIONS = "CLEAR_FOUNDATION_QUESTIONS";
export const CLEAR_MASTER_QUESTIONS = "CLEAR_MASTER_QUESTIONS";
export const CLEAR_SCORE = "CLEAR_SCORE";
export const PROFILE_UPDATE = "PROFILE_UPDATE";
export const UPDATE_USER_ACCESS = "UPDATE_USER_ACCESS";
export const UPDATE_USER_ACCESS_FAILED = "UPDATE_USER_ACCESS_FAILED";
export const UPDATE_FAILED = "UPDATE_FAILED";
export const PASSWORD_FORGOT_SUCCESS = "PASSWORD_FORGOT_SUCCESS";
export const PASSWORD_FORGOT_FAILED = "PASSWORD_FORGOT_FAILED ";
export const POST_FOUNDATION_ANS = "POST_FOUNDATION_ANS";
export const POST_MASTER_ANS = "POST_MASTER_ANS";
export const UPDATE_USERS_ACCESS = "UPDATE_USERS_ACCESS";
export const CLEAR_DATA = "CLEAR_DATA";
export const DOWNLOAD_FOUNDATION_CERTIFICATE =
  "DOWNLOAD_FOUNDATION_CERTIFICATE";
export const DOWNLOAD_MASTER_CERTIFICATE = "DOWNLOAD_MASTER_CERTIFICATE";
export const SHOW_LOADING = "SHOW_LOADING";
