import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Spinner } from "reactstrap";
import { loadUsers, updateAccess } from "../../store/actions/authAction";
import {
  sendMasterCertficate,
  sendFoundationCertficate,
} from "../../store/actions/masterScoreAction";
import { clearErrors } from "../../store/actions/errorAction";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Popover from "@material-ui/core/Popover";
import TableHead from "@material-ui/core/TableHead";
import MenuItem from "@material-ui/core/MenuItem";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import EditIcon from "@material-ui/icons/Edit";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import "./Admin.css";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import UpdateModal from "../resources/updateModal";
import { Alert } from "reactstrap";
import { Button, IconButton } from "@material-ui/core";
import { loadingToggleAction } from "../../store/actions/masterAssessmentAction";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  roots: {
    flexGrow: 1,
    margin: theme.spacing(0, 3),
  },
  contentHeader: {
    paddingTop: theme.spacing(20),
    paddingBottom: theme.spacing(5),
  },
  papers: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    paddingBottom: theme.spacing(5),
    marginTop: theme.spacing(4),
  },

  about: {
    paddingTop: theme.spacing(7),
  },
  mainHeading: {
    fontSize: "1.7em",
    color: "#474747",
  },
  heading: {
    fontSize: "1.5em",
    paddingLeft: theme.spacing(3),
    color: "#fff",
  },
  span: {
    paddingTop: theme.spacing(5),
  },
  courses: {
    fontSize: "1.2em",
    color: "#474747",
  },
  header3: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column", // On small screens, switch to column layout
      alignItems: "flex-start", // Align items to the left on small screens
    },
    // Add this line to push the TextField to the right

    marginTop: "-45px",
    backgroundColor: "#ff9800",
    width: "100%",
  },
  headerText: {
    padding: theme.spacing(2.5, 0),
    color: "#474747",
  },

  headingSpace: {
    padding: theme.spacing(2, 0),
  },
  title: {
    marginBottom: theme.spacing(8),
  },
  container: {
    maxHeight: 440,
  },
  fab: {
    color: "#fff",
    fontSize: "3em",
    position: "relative",
    left: "15%",
    top: "30%",
  },
  header5: {
    position: "relative",
    marginTop: "-50px",
    backgroundColor: "#ff9800",
    width: "70px",
    height: "70px",
  },
  table: {
    overflow: "hide",
  },
  inputBox: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2), // Add right margin
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#FFF", // White border color
      },
    },

    "& .MuiInputBase-input": {
      color: "#FFF", // Set text color to black
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Users = (props) => {
  const [editData, setEditData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const classes = useStyles();
  const { users } = props.auth;
  // const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const pageNumbers = [];
  const [open, setOpen] = useState(null);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const dispatch = useDispatch();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [msg, setMsg] = useState("");

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const toggleModal = () => {
    setShowModal((prevShowModal) => !prevShowModal);
  };
  const setUpdateDetails = async (data, check) => {
    await setEditData(data);
    if (check) {
      await toggleModal();
    }
  };
  const handleUpdate = async (data) => {
    props.updateAccess(
      { ...data, participantId: editData.id },
      props.auth.token
    );
    props.clearErrors();
  };
  const [filter, setFilter] = useState("");

  const handleChangeFilter = async (event) => {
    const searchQuery = event.target.value;
    setFilter(searchQuery);

    // You can apply filtering logic here and update your user list accordingly.
    // For example, you can use the 'filter' state to filter the users based on their names or any other criteria.
  };
  const [successMsg, setSuccessMsg] = useState("");

  useEffect(() => {
    if (filter !== " ") {
      props.loadUsers(filter);
    } else {
      props.loadUsers();
    }
    if (props.payload) {
      setSuccessMsg(props.payload.message)
    }
    // setTimeout(() => {
    //   props.loadUsers();
    // }, 2000);
  }, [filter, props.error, props.payload]);

  // GET CURRENT POST
  // const indexOfLastPost = currentPage * postsPerPage;
  // const indexOfFirstPost = indexOfLastPost - postsPerPage;
  // let currentPost = users.data ? users.data.users.slice(indexOfFirstPost, indexOfLastPost) : users;
  const currentPosts = users.data ? users.data.users : users;
  const totalPost = users.data
    ? Math.ceil(users.data.users.length / postsPerPage)
    : users;

  for (let i = 1; i <= totalPost; i++) {
    pageNumbers.push(i);
  }

  const handleModalCancel = () => {
    setShowModal(false); // Sync the parent showModal state with the modal's isModalOpen state when canceled
  };

  const handleOpenMenu = (event, staff) => {
    try {
      setUpdateDetails(staff, false);
      setOpen(event.currentTarget);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const sendMasterCertficatef = async () => {
    dispatch(loadingToggleAction(true));
    await props.sendMasterCertficate(editData.id, props.auth.token);
    console.log(props.payload);
  };

  const sendFoundationCertficatef = async () => {
    dispatch(loadingToggleAction(true));
    await props.sendFoundationCertficate(editData.id, props.auth.token);
  };
  return (
    <div className={classes.roots}>
      {props.showLoading && (
        <>
          <Backdrop className={classes.backdrop} open>
            <CircularProgress color="inherit" />
          </Backdrop>
        </>
      )}
      {props.error.msg.message ? (
        <Alert
          color="danger"
          style={{ textAlign: "center", height: "50px", fontSize: "20px" }}
        >
          {props.error.msg.message}
        </Alert>
      ) : props.payload.message ? (
        <Alert
          color="success"
          style={{ textAlign: "center", height: "50px", fontSize: "20px" }}
        >
          {props.payload.message}
        </Alert>
      ) : (
        ""
      )}
      {showModal && (
        <UpdateModal
          showModal={showModal}
          onCancel={handleModalCancel}
          user={editData}
          handleUpdate={handleUpdate}
        />
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <div className={classes.title}></div>
          <Paper className={classes.papers}>
            <Paper className={classes.header3}>
              <div className={classes.headerText}>
                <Typography paragraph className={classes.heading}>
                  Users List
                </Typography>
              </div>
              <TextField
                label="Filter Users"
                variant="outlined"
                className={classes.inputBox}
                value={filter}
                onChange={handleChangeFilter}
              />
            </Paper>
            {users.data ? (
              <TableContainer className={classes.container}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Id</TableCell>
                      <TableCell>First Name</TableCell>
                      <TableCell>Last Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Organisation</TableCell>
                      <TableCell>isAdmin</TableCell>
                      <TableCell>Edit</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentPosts
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((user, i) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={user.id}
                          >
                            <TableCell>{user.id}</TableCell>
                            <TableCell>{user.firstname}</TableCell>
                            <TableCell>{user.lastname}</TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>{user.organisation}</TableCell>
                            <TableCell>{String(user.isAdmin)}</TableCell>
                            <TableCell>
                              <IconButton
                                onClick={() => setUpdateDetails(user, true)}
                              >
                                <EditIcon />
                              </IconButton>
                            </TableCell>
                            <TableCell>
                              <IconButton
                                onClick={(e) => {
                                  handleOpenMenu(e, user);
                                }}
                              >
                                <MoreVertIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>{" "}
              </TableContainer>
            ) : (
              <div id="spinner">
                <Spinner type="grow" color="primary" />
                <Spinner type="grow" color="secondary" />
                <Spinner type="grow" color="success" />
                <Spinner type="grow" color="danger" />
                <Spinner type="grow" color="warning" />
                <Spinner type="grow" color="info" />
                <Spinner type="grow" color="light" />
                <Spinner type="grow" color="dark" />
              </div>
            )}
            {/* <div style={{marginTop: '30px'}}> 
                <nav>
                    <ul className="pagination">
                        {pageNumbers.map(number => (
                            <li key={number} className="page-item">
                            <NavLink  onClick={() => paginate(number)} href="#" className="page-link">{number}</NavLink>
                            </li>
                        ))}
                    </ul> */}
            {/* </nav> */}

            <Popover
              open={Boolean(open)}
              anchorEl={open}
              onClose={handleCloseMenu}
              anchorOrigin={{ vertical: "top", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <MenuItem>
                <IconButton onClick={() => sendFoundationCertficatef()}>
                  Send Foundation Certificate
                </IconButton>
              </MenuItem>
              <MenuItem>
                <IconButton onClick={() => sendMasterCertficatef()}>
                  Send Practitioner Certificate
                </IconButton>
              </MenuItem>
            </Popover>

            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={users.data ? users.data.users.length : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

Users.propTypes = {
  auth: PropTypes.object.isRequired,
  loadUsers: PropTypes.func.isRequired,
  updateAccess: PropTypes.func.isRequired,
  sendMasterCertficate: PropTypes.func.isRequired,
  sendFoundationCertficate: PropTypes.func.isRequired,
  error: PropTypes.object.isRequired,
  clearErrors: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  error: state.error,
  showLoading: state.masterAssessment.showLoading,
  payload: state.masterAssessment.payload,
});

export default connect(mapStateToProps, {
  loadUsers,
  updateAccess,
  sendFoundationCertficate,
  sendMasterCertficate,
  clearErrors,
})(Users);
