import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../src/store/reducers';

// const initialState = {};

const middleware = [thunk];

// const saveToLocalStorage = (state) => {
//     try {
//         const serializedState = JSON.stringify(state);
//         localStorage.setItem('state', serializedState)
//     }catch (e){
//         console.log(e)
//     }
// };

// const loadLocalStorage = () => {
//     try {
//         const serializedState = localStorage.getItem('state');
//         if(serializedState === null) return undefined
//         return JSON.parse(serializedState)
//     }catch (e) {
//         console.log(e);
//         return undefined;
//     }
// };


// const persistedState = loadLocalStorage();

// const store = createStore(rootReducer, compose(applyMiddleware(...middleware),
//     window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// ));
const store = createStore(rootReducer, compose(applyMiddleware(...middleware)));

// store.subscribe(() => {
//     saveToLocalStorage({
//         foundationAssessment: store.getState().foundationAssessment
//     })
//     store.getState()});

export default store;